import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

import './TimeScrubber.scss';
import { Slider } from '@mantine/core';
import { ReactSVG } from 'react-svg';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { TileSource } from '../../apis/rellserver';
import { getSourceIndexOfDate } from '../../utils/utils';

dayjs.extend(advancedFormat);

interface TimeScrubberProps {
  currentTileSourceIndex: number;
  tileSources: TileSource[];
  setCurrentSource: (url: string) => void;
  setCurrentTileSourceIndex: (index: number) => void;
  setCurrentDisplayDate: (displayDate: string) => void;
}

export default function TimeScrubber({
  currentTileSourceIndex,
  setCurrentTileSourceIndex,
  tileSources,
  setCurrentSource,
  setCurrentDisplayDate,
}: TimeScrubberProps) {
  const [showScrubber, setShowScrubber] = useState<boolean>(false);

  useEffect(() => {
    if (tileSources.length > 0 && currentTileSourceIndex) {
      const newSourceUrl = tileSources[currentTileSourceIndex].url;
      setCurrentSource(newSourceUrl);
      const date = tileSources[currentTileSourceIndex].date.format('YYYY-MM-DD');
      setCurrentDisplayDate(date);
    }
  }, [currentTileSourceIndex]);

  if (tileSources.length === 0) {
    return null;
  }

  function getDisplayDayOfWeek(): string {
    const isToday = dayjs().startOf('day').isSame(dayjs(tileSources?.[currentTileSourceIndex].date).startOf('day'));
    return `${tileSources?.[currentTileSourceIndex].date?.format('dddd')}${isToday ? ' (Today)' : ''}`;
  }

  function setDateToToday(): void {
    setCurrentTileSourceIndex(getSourceIndexOfDate(tileSources, dayjs()));
  }

  const modulusOperand = Math.floor(tileSources.length / 4); // calculateModulus(scrubberRect.width, tileSources.length);

  return (
    <div className="time-scrubber">
      <div className="time-scrubber-minimized">
        <ReactSVG src="assets/img/time-selector.svg" style={{ placeSelf: 'center' }} onClick={() => setDateToToday()} />
        <div className="time-scrubber-header" onClick={() => setShowScrubber((show) => !show)}>
          <span className="time-scrubber-dayofweek">{getDisplayDayOfWeek()}</span>
          <span className="time-scrubber-date">
            {tileSources?.[currentTileSourceIndex].date?.format('MMM Do, YYYY')}
          </span>
        </div>
      </div>
      <Card className="bottom-rounded time-scrubber-body" style={{ display: showScrubber ? 'block' : 'none' }}>
        <Card.Body>
          <Slider
            defaultValue={currentTileSourceIndex}
            value={currentTileSourceIndex}
            size={6}
            step={1}
            min={0}
            max={tileSources.length - 1}
            color="rgba(255, 255, 255, 1)"
            onChange={setCurrentTileSourceIndex}
            showLabelOnHover={false}
            label={(value) => tileSources[value].date?.format('MMM D')}
            marks={tileSources.map((tileSource, i) => ({
              value: i,
              label: i % modulusOperand === 0 ? tileSource.date?.format('MMM D') : undefined,
            }))}
          />
        </Card.Body>
      </Card>
    </div>
  );
}
