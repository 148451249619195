import { createContext, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Session from 'supertokens-web-js/recipe/session';
import { getUserInfo } from '../apis/rellserver';

interface UserAuthState {
  isAuthenticated: boolean;
  firstName?: string;
  lastName?: string;
  invitationCode?: string;
}

interface AuthContextType {
  user: UserAuthState;
  login: () => void;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactElement | null;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: Readonly<AuthProviderProps>) {
  const [user, setUser] = useState<UserAuthState>({ isAuthenticated: false });
  const navigate = useNavigate();

  const hydrate = () => {
    getUserInfo().then((userInfo) => {
      if (userInfo) {
        setUser({
          ...user,
          isAuthenticated: true,
          firstName: userInfo.metadata.first_name,
          lastName: userInfo.metadata.last_name,
        });
      }
    });
  };

  useEffect(() => {
    Session.doesSessionExist().then((doesSessionExist) => {
      if (!doesSessionExist) {
        return;
      }
      hydrate();
    });
  }, []);

  const login = () => {
    // Redirect for now. Needs to change when we start doing more dynamic login.
    hydrate();
    navigate('/');
  };

  const logout = () => {
    setUser({ ...user, isAuthenticated: false });
    navigate('/', { replace: true });
    Session.signOut().then(() => setUser({ isAuthenticated: false }));
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('authContext has been called outside of the AuthContext provider.');
  }
  return authContext;
};
