import './SpeciesSelector.scss';
import { Select } from '@mantine/core';
import { ReactSVG } from 'react-svg';
import classes from './SpeciesSelectorDropdown.module.scss';
import { SpeciesSelectorDropdownItem } from '../../lib/models/interfaces';

interface SpeciesSelectorProps {
  speciesList: SpeciesSelectorDropdownItem[]; // todo: move this type to a better location
  selectedSpecies: SpeciesSelectorDropdownItem;
  setSelectedSpecies: (selectedSpecies: SpeciesSelectorDropdownItem) => void;
}

export default function SpeciesSelector({ speciesList, selectedSpecies, setSelectedSpecies }: SpeciesSelectorProps) {
  function setSpeciesObject(value: string | null) {
    const foundSpecies = speciesList.find((x) => x.speciesName === value);
    if (foundSpecies) setSelectedSpecies(foundSpecies);
  }

  const speciesGroupings = [
    {
      group: 'Available',
      items: speciesList.map((species) => ({
        value: species.speciesName,
        label: species.displayName,
        disabled: species.disabled,
      })),
    },
    {
      group: 'Coming Soon',
      items: [
        {
          value: 'black-trumpet',
          label: 'Black Trumpet',
          disabled: true,
        },
        {
          value: 'matsutake',
          label: 'Matsutake (松茸)',
          disabled: true,
        },
      ],
    },
  ];

  return (
    <div className="rounded species-selector-container">
      <Select
        className="species-selector"
        classNames={classes}
        variant="unstyled"
        size="xs"
        label="Species Type"
        placeholder="Select species"
        value={selectedSpecies.speciesName}
        onChange={(value) => setSpeciesObject(value)}
        allowDeselect={false}
        nothingFoundMessage="Species not found..."
        rightSection={<ReactSVG src="/assets/img/arrow-down.svg" />}
        data={speciesGroupings}
        comboboxProps={{ shadow: 'md', transitionProps: { transition: 'scale-y', duration: 200 } }}
      />
    </div>
  );
}
