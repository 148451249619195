import { useState } from 'react';
import { BarChart, Bar, ResponsiveContainer, Cell, XAxis, Tooltip, TooltipProps, YAxis } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import './SuitabilityHistoryGraph.scss';
import * as _ from 'lodash';
import { Select } from '@mantine/core';
import classes from './SuitabilityHistoryGraphDropdown.module.scss';
import { getDisplayDate, getSuitabiltyDisplayText } from '../../utils/utils';
import { HistoricalDisplayItem } from '../../lib/models/interfaces';

interface SuitabilityHistoryGraphProps {
  historicalDaysDisplay: HistoricalDisplayItem;
  setHistoricalDaysDisplay: (historicalDays: HistoricalDisplayItem) => void;
  suitabilityHistory: SuitabilityHistoryGraphInterface | null;
}

export interface SuitabilityHistoryGraphInterface {
  suitability: { probability: number; date: string }[];
  currentCondition: 'Optimal' | 'Suitable' | 'Marginal' | 'Unsuitable';
  periodLength: number;
}

export interface SuitabilityHistoryGraphDataFormat {
  pv: number;
}

interface CustomTooltipProps extends TooltipProps<ValueType, NameType> {
  historicalDaysDisplay: HistoricalDisplayItem;
}

const tooltipDurationFormat = {
  day: 'ddd, MMM DD',
  month: 'MMMM YYYY',
  year: 'YYYY',
};

function CustomTooltip({ active, payload, label, historicalDaysDisplay }: CustomTooltipProps) {
  if (active && payload?.length) {
    return (
      <div className="popover__content">
        <div className="popover__message">
          <div style={{ whiteSpace: 'nowrap' }}>
            <strong>{`${getDisplayDate(
              label as string,
              true,
              tooltipDurationFormat[historicalDaysDisplay.unit],
            )}`}</strong>
            <br />
            {payload?.[0]?.value !== undefined && (
              <>
                {getSuitabiltyDisplayText((payload[0].value as number) * 100)}{' '}
                {`(${_.round((payload[0].value as number) * 100, 2)}%)`}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  return null;
}

export default function SuitabilityHistoryGraph({
  suitabilityHistory,
  setHistoricalDaysDisplay,
  historicalDaysDisplay,
}: SuitabilityHistoryGraphProps) {
  const [activeIndex, setActiveIndex] = useState<number>();
  const durationValues: Map<string, HistoricalDisplayItem> = new Map<string, HistoricalDisplayItem>([
    [
      '7-days',
      {
        value: '7-days',
        unit: 'day',
        duration: 7,
        display: 'Last 7 Days',
      },
    ],
    [
      '30-days',
      {
        value: '30-days',
        unit: 'day',
        duration: 30,
        display: 'Last 30 Days',
      },
    ],
    [
      '12-months',
      {
        value: '12-months',
        unit: 'month',
        duration: 12,
        display: 'Last 12 Months',
      },
    ],
  ]);

  const durationFormat = {
    day: 'MMM DD',
    month: 'MMM',
    year: 'YYYY',
  };

  function changeAnalysisDuration(selectedValue: string | null) {
    if (selectedValue) {
      setHistoricalDaysDisplay(
        durationValues.get(selectedValue) ?? {
          value: '12-months',
          unit: 'month',
          duration: 12,
          display: 'Last 12 Months',
        },
      );
    }
  }

  function formatXAxis(value: string): string {
    if (historicalDaysDisplay.value === '7-days') {
      return getDisplayDate(value, false, 'ddd');
    }

    return getDisplayDate(value, false, durationFormat[historicalDaysDisplay.unit]);
  }

  return (
    <>
      <div className="history-condition-container">
        <span style={{ fontSize: '16px', fontWeight: '600' }}>Historic conditions</span>
        <Select
          classNames={classes}
          aria-label="analysis select duration"
          onChange={(_value) => changeAnalysisDuration(_value)}
          value={historicalDaysDisplay.value}
          style={{ width: '10.5em' }}
          styles={{
            input: {
              fontSize: '12px',
            },
            option: {
              fontSize: '12px',
            },
          }}
          data={Array.from(durationValues.values()).map((durationValue) => ({
            value: durationValue.value,
            label: durationValue.display,
          }))}
        />
      </div>
      <div className="history-graph">
        <ResponsiveContainer>
          <BarChart
            width={500}
            height={300}
            data={suitabilityHistory?.suitability}
            onMouseMove={(state) => {
              setActiveIndex(state?.activeTooltipIndex);
            }}
          >
            <Tooltip
              content={<CustomTooltip historicalDaysDisplay={historicalDaysDisplay} />}
              position={{ y: -75 }}
              offset={-55}
            />
            <Bar dataKey="probability">
              {suitabilityHistory?.suitability.map((entry, index) => (
                <Cell cursor="pointer" fill={index === activeIndex ? '#000' : '#D0D0D0'} key={`${entry.date}`} />
              ))}
            </Bar>
            <XAxis
              dataKey="date"
              tickFormatter={(tick) => formatXAxis(tick as string)}
              interval="equidistantPreserveStart"
              tick={{ dy: 10 }}
            />
            <YAxis type="number" domain={[0, 1]} axisLine={false} tick={false} height={0} width={0} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}
