import { useEffect, useState } from 'react';

export interface GeolocationState {
  state?: 'granted' | 'prompt' | 'denied';
  isReady: boolean;
  position?: GeolocationPosition;
}

export function useGeolocation() {
  const [geolocationState, setGeolocationState] = useState<GeolocationState>({
    isReady: false,
  });

  useEffect(() => {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      navigator.geolocation.getCurrentPosition((position) => {
        setGeolocationState({
          state: result.state,
          isReady: result.state === 'granted',
          position,
        });
      });
    });
    // todo: add error handling
  }, []);

  return geolocationState;
}
