import { ReactSVG } from 'react-svg';
import { useMap } from 'react-map-gl';
import { flyToGeolocation } from '../../utils/maplibre';
import { useGeolocation } from '../../hooks/useGeolocation';

export function MapSideControl() {
  const { default: map } = useMap();
  const geolocation = useGeolocation();

  return (
    <ReactSVG
      src="assets/img/current-location-button-icon.svg"
      style={{ placeSelf: 'center' }}
      onClick={() => flyToGeolocation(map, geolocation)}
    />
  );
}
