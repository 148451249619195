import './ActionButtonDrawer.scss';
import { Layers } from 'react-feather';
import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { v4 as uuidv4 } from 'uuid';
import { MapSideControl } from '../MapSideControl/MapSideControl';
import { MapSideZoomControl } from '../MapSideZoomControl/MapSideZoomControl';
import { gradientColorMap } from '../../utils/utils';

export default function ActionButtonDrawer() {
  const [showLegend, setShowLegend] = useState<boolean>(false);

  function toggleLegend() {
    setShowLegend(!showLegend);
  }

  const icons = [
    {
      id: 'legend',
      icon: <ReactSVG src="assets/img/map-legend.svg" style={{ placeSelf: 'center' }} onClick={() => toggleLegend()} />,
      visible: true,
    },
    {
      id: 'layers',
      icon: <Layers style={{ width: '40px', height: '40px', padding: '10px' }} />,
      visible: false,
    },
    {
      id: 'userLocation',
      icon: <MapSideControl />,
      visible: true,
    },
  ];

  return (
    <div className="floating-button-container">
      {showLegend && (
        <div className="suitability-legend">
          <div className="rounded suitability-gradient-container">
            <div className="suitability-gradient">
              {[...gradientColorMap].reverse().map((entry) => (
                <span
                  className="suitability-gradient-item"
                  key={uuidv4()}
                  style={{
                    background: entry.color.toUpperCase(),
                  }}
                />
              ))}
            </div>
            <div className="suitability-gradient-labels">
              <span>Optimal</span>
              <span>Suitable</span>
              <span>Marginal</span>
              <span>Unsuitable</span>
            </div>
          </div>
        </div>
      )}
      <div className="button-container">
        {icons.map((icon) => (
          <div key={icon.id} style={{ display: icon.visible ? 'block' : 'none' }}>
            <div className="rounded floating-button">{icon.icon}</div>
          </div>
        ))}
        <div className="zoom-container">
          <MapSideZoomControl />
        </div>
      </div>
    </div>
  );
}
