import axios, { AxiosResponse } from 'axios';
import { GeocodeResult, PlusCode } from '@googlemaps/google-maps-services-js';
import dayjs from 'dayjs';
import { SuitabilityHistoryGraphInterface } from '../components/SuitabilityHistoryGraph/SuitabilityHistoryGraph';
import { DATE_FORMAT } from '../lib/models/consts';

const API_URL: string = import.meta.env.VITE_API_URL as string;

export interface GetSuitabilityHistoryRequest {
  startDate: string;
  species: string;
  duration: number;
  unit: 'day' | 'month' | 'year';
  zoom: string;
  lat: string;
  lng: string;
}

export async function getSuitabilityHistory(
  request: GetSuitabilityHistoryRequest,
): Promise<AxiosResponse<SuitabilityHistoryGraphInterface>> {
  return axios.get<SuitabilityHistoryGraphInterface>(`${API_URL}/historical-data`, {
    params: {
      ...request,
    },
  });
}

export interface ReverseGeocode {
  plus_code: PlusCode;
  results: GeocodeResult[];
}

export async function getReverseGeocode(lat: number, lng: number): Promise<AxiosResponse<ReverseGeocode>> {
  return axios.post<ReverseGeocode>(`${API_URL}/reverse-geocode`, {
    lat,
    lng,
  });
}

export interface TileSource {
  imageType: string;
  url: string;
  name: string;
  date: dayjs.Dayjs;
}

export interface TileSourcesParams {
  modelName: string;
  startDate?: string;
  endDate?: string;
}

export async function getTileSources({ modelName }: TileSourcesParams): Promise<TileSource[]> {
  const response = await axios.get<TileSource[]>(`${API_URL}/pmtiles/${modelName}`);
  const tileSources = response.data;

  // TODO: Change the name on the server side
  return tileSources.map((item: TileSource) => ({
    ...item,
    url: `pmtiles://${item.url}`,
    date: dayjs(item.name.split('/').slice(-1)[0].split('.')[0], DATE_FORMAT),
  }));
}

export interface AuthUserMetadataResponse {
  metadata: { first_name: string; last_name: string; invitationCode: string };
}

export async function getUserInfo(): Promise<AuthUserMetadataResponse | null> {
  const response = await axios.get<AuthUserMetadataResponse>(`${API_URL}/auth/user/info`);
  return response.data;
}
