import { useMap } from 'react-map-gl';
import { Minus, Plus } from 'react-feather';
import './MapSideZoomControl.scss';

export function MapSideZoomControl() {
  const { default: map } = useMap();
  enum ZoomDirection {
    IN,
    OUT,
  }

  function zoomMap(direction: ZoomDirection) {
    const mapRef = map?.getMap();
    const currZoom = mapRef?.getZoom() ?? 6;
    switch (direction) {
      case ZoomDirection.IN:
        mapRef?.easeTo({ zoom: currZoom + 1 });
        break;
      case ZoomDirection.OUT:
        mapRef?.easeTo({ zoom: currZoom - 1 });
        break;
      default:
        mapRef?.easeTo({ zoom: currZoom + 1 });
        break;
    }
  }

  return (
    <div className="rounded zoom-control-container">
      <Plus
        className="zoom-control"
        color="#FFFFFF"
        style={{ placeSelf: 'center' }}
        onClick={() => zoomMap(ZoomDirection.IN)}
      />
      <hr />
      <Minus
        className="zoom-control"
        color="#FFFFFF"
        style={{ placeSelf: 'center' }}
        onClick={() => zoomMap(ZoomDirection.OUT)}
      />
    </div>
  );
}
