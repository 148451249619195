import { CSSProperties, useEffect, useState } from 'react';
import * as _ from 'lodash';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { MapLayerMouseEvent } from 'maplibre-gl';
import { ReactSVG } from 'react-svg';
import { Accordion, Card, Group, Title, CloseButton } from '@mantine/core';
import { ClickedFeature } from '../MapContainer/MapContainer';
import SuitabilityHistoryGraph, {
  SuitabilityHistoryGraphInterface,
} from '../SuitabilityHistoryGraph/SuitabilityHistoryGraph';
import './FeatureDetails.scss';
import { HistoricalDisplayItem } from '../../lib/models/interfaces';
import { getDisplayDate, getHexCodeForThreshold, getSuitabiltyDisplayText, gradientColorMap } from '../../utils/utils';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

// Render SVG
interface FeatureDetailsProps {
  clickedFeature: ClickedFeature;
  setClickedFeature: (clickedFeature: ClickedFeature) => void;
  suitabilityHistory: SuitabilityHistoryGraphInterface | null;
  setHistoricalDaysDisplay: (historicalDays: HistoricalDisplayItem) => void;
  currentDisplayedDate: string;
  isMobile: boolean;
  historicalDaysDisplay: HistoricalDisplayItem;
  setClickedPoint: (clickedPoint: MapLayerMouseEvent | null) => void;
}

export default function FeatureDetails({
  clickedFeature,
  setClickedFeature,
  suitabilityHistory,
  setHistoricalDaysDisplay,
  currentDisplayedDate,
  historicalDaysDisplay,
  isMobile,
  setClickedPoint,
}: FeatureDetailsProps) {
  function getSuitability(): number {
    return _.round(_.mean(clickedFeature?.featureLayers.map((layer) => layer.properties.probability)) * 100, 2);
  }

  function getSuitabilityRaw(): number {
    return _.mean(clickedFeature?.featureLayers.map((layer) => layer.properties.probability));
  }

  function getDisplayText(
    unitType: DisplayItemUnit,
    value: string,
    secondaryDisplayFn?: (value: string) => string,
  ): string {
    if (!value) {
      return 'n/a';
    }

    const number = Number(value);
    if (!Number.isNaN(number)) {
      const displayText = `${_.round(number, 2)} ${unitType}`;
      return secondaryDisplayFn ? `${displayText} (${secondaryDisplayFn(value)})` : displayText;
    }
    return value?.toString();
  }

  interface DisplayConfig {
    sections: DisplayConfigSection[];
  }

  interface DisplayConfigSection {
    id: string;
    headerText: string;
    items: DisplayItem[];
  }

  type DisplayItemUnit = 'pH' | 'g/kg' | '%' | '°C' | 'kg/dm3' | 'kg/m3' | 'mm' | 'probability';

  interface DisplayItem {
    id: string;
    unit: DisplayItemUnit;
    attributeName: string;
    displayName: string;
    visible: boolean;
    secondaryDisplayFn?: (value: string) => string;
  }

  const otherDetailsDisplay: DisplayConfig = {
    sections: [
      {
        id: 'section-soil-chemistry',
        headerText: 'Soil Chemistry',
        items: [
          {
            id: 'section-soil-chemistry-item-1',
            unit: 'pH',
            attributeName: 'phh2o_5-15cm',
            displayName: 'pH',
            visible: true,
            secondaryDisplayFn: (value: string): string => {
              const ph = Number(value);
              if (Number.isNaN(value)) {
                return '';
              }

              if (ph < 7) {
                return 'Acidic';
              }
              if (ph > 7) {
                return 'Basic';
              }

              return 'Neutral';
            },
          },
          {
            id: 'section-soil-chemistry-item-2',
            unit: 'g/kg',
            attributeName: 'nitrogen_5-15cm',
            displayName: 'Total Nitrogen',
            visible: false,
          },
        ],
      },
      {
        id: 'section-soil-texture',
        headerText: 'Soil Texture',
        items: [
          {
            id: 'section-soil-texture-item-1',
            unit: '%',
            attributeName: 'sand_5-15cm',
            displayName: 'Sand',
            visible: true,
          },
          {
            id: 'section-soil-texture-item-2',
            unit: '%',
            attributeName: 'silt_5-15cm',
            displayName: 'Silt',
            visible: true,
          },
          {
            id: 'section-soil-texture-item-3',
            unit: '%',
            attributeName: 'clay_5-15cm',
            displayName: 'Clay',
            visible: true,
          },
          {
            id: 'section-soil-texture-item-4',
            unit: 'kg/dm3',
            attributeName: 'bdod_5-15cm',
            displayName: 'Bulk density of fine earth fraction',
            visible: false,
          },
          {
            id: 'section-soil-texture-item-5',
            unit: 'kg/m3',
            attributeName: 'ocd_5-15cm',
            displayName: 'Organic Carbon Density',
            visible: false,
          },
          {
            id: 'section-soil-texture-item-6',
            unit: '%',
            attributeName: 'cfvo_5-15cm',
            displayName: 'Vol. fraction of coarse fragments (> 2 mm)',
            visible: false,
          },
        ],
      },
      {
        id: 'section-weather',
        headerText: 'Weather (avg. over last 2 weeks)',
        items: [
          {
            id: 'section-weather-item-1',
            unit: 'mm',
            attributeName: 'pr_2weeks',
            displayName: 'Daily Precipitation',
            visible: true,
          },
          {
            id: 'section-weather-item-2',
            unit: '°C',
            attributeName: 'mean_temp2w',
            displayName: 'Daily Temperature',
            visible: true,
          },
          {
            id: 'section-weather-item-3',
            unit: '°C',
            attributeName: 'mean_dailyRange2w',
            displayName: 'Daily Range (max - min)',
            visible: true,
          },
        ],
      },
    ],
  };

  const growthConditionDisplayText: Record<string, string> = {
    Optimal: 'Ideal conditions with perfect temperature, humidity, and soil for abundant, healthy mushroom growth.',
    Suitable:
      'Good, but not perfect, conditions with adequate moisture and nutrients for satisfactory mushroom growth.',
    Marginal:
      'Challenging conditions with just enough moisture and temperature for slower, less productive mushroom growth.',

    Unsuitable: 'Inadequate conditions with extreme or lacking factors, leading to unsuccessful mushroom growth.',
  };

  const [accordionValue, setAccordionValue] = useState<string | null>(null);
  const minimizedDetailsCss: CSSProperties = {
    transform: 'translateY(65vh)',
    touchAction: 'none',
    overflowY: 'hidden',
  };

  const expandedDetailsCss: CSSProperties = {
    transform: 'translateY(0vh)',
  };

  const [detailsIsExpanded, setDetailsIsExpanded] = useState<boolean>(false);

  const [currentContainerStyle, setCurrentContainerStyle] = useState<CSSProperties>({});

  useEffect(() => {
    if (isMobile) {
      setCurrentContainerStyle(detailsIsExpanded ? expandedDetailsCss : minimizedDetailsCss);
    } else {
      setCurrentContainerStyle(expandedDetailsCss);
    }
  }, [detailsIsExpanded, isMobile]);

  return (
    <Card
      shadow="md"
      padding={24}
      radius="md"
      withBorder
      className="feature-details-container"
      style={currentContainerStyle}
    >
      <ReactSVG
        src="assets/img/details-chevron.svg"
        onClick={() => setDetailsIsExpanded(!detailsIsExpanded)}
        className="drag-handle"
        style={{
          transform: detailsIsExpanded ? 'rotate(3.142rad)' : 'rotate(0)',
        }}
      />

      <Group justify="right">
        <CloseButton
          aria-label="Close modal"
          size="md"
          className="rounded-circle"
          style={{
            background: '#efefef',
          }}
          icon={<ReactSVG src="assets/img/cross-button.svg" style={{ fontSize: '12px' }} />}
          onClick={() => {
            setClickedFeature({
              addressSnippet: '',
              latitude: 0,
              longitude: 0,
              featureLayers: [],
            });
            setClickedPoint(null);
            setDetailsIsExpanded(false);
          }}
        />
      </Group>

      <Title order={3}>Growth conditions</Title>
      <Group justify="space-between" mt="md" mb="xs" mr="lg">
        <div>
          <Title order={4} fw={400}>
            {getDisplayDate(currentDisplayedDate, true, 'ddd, MMM DD, YYYY')}
          </Title>
          <Title order={4} fw={600}>
            {getSuitabiltyDisplayText(getSuitability())}
          </Title>
        </div>
        <div
          className="hexagon"
          style={
            {
              '--hexagon-fill-color': getHexCodeForThreshold(gradientColorMap, getSuitabilityRaw()),
            } as React.CSSProperties
          }
        />
      </Group>
      <div className="feature-suitability-gradient-container">
        <div className="suitability-arrow" style={{ left: `${getSuitability()}%` }} />
        <div className="suitability-gradient">
          {gradientColorMap.map((entry) => (
            <span
              className="suitability-gradient-item"
              key={uuidv4()}
              style={{
                background: entry.color.toUpperCase(),
              }}
            />
          ))}
        </div>
        <div className="suitability-gradient-ticks">
          <span />
          <span />
          <span />
          <span />
        </div>
        <div className="suitability-gradient-labels">
          <span>Unsuitable</span>
          <span>Marginal</span>
          <span>Suitable</span>
          <span>Optimal</span>
        </div>
      </div>
      <Card.Section mt="lg" withBorder>
        <div className="growth-condition-text">
          {growthConditionDisplayText[getSuitabiltyDisplayText(getSuitability())]}
        </div>
      </Card.Section>
      <Card.Section pb="md" withBorder inheritPadding>
        <SuitabilityHistoryGraph
          suitabilityHistory={suitabilityHistory}
          setHistoricalDaysDisplay={setHistoricalDaysDisplay}
          historicalDaysDisplay={historicalDaysDisplay}
        />
      </Card.Section>
      <Group mt="lg">
        <Title order={5} fw={600}>
          Other details
        </Title>
      </Group>
      <Accordion variant="filled" value={accordionValue} onChange={setAccordionValue} mt="md">
        {otherDetailsDisplay.sections.map((section: DisplayConfigSection) => (
          <Accordion.Item key={section.id} value={section.id}>
            <Accordion.Control pl={0} pr={0} aria-label="available soil properties">
              {section.headerText}
            </Accordion.Control>
            <Accordion.Panel ml={0} mr={0} style={{ maxHeight: '200px', fontSize: '16px' }}>
              {section.items
                .filter((x) => x.visible)
                .map((item: DisplayItem) => (
                  <div key={item.attributeName} className="col">
                    <dt>
                      <small>{item.displayName}</small>
                    </dt>
                    <dd>
                      {getDisplayText(
                        item.unit,
                        clickedFeature.featureLayers[0].properties[item.attributeName],
                        item.secondaryDisplayFn,
                      )}
                    </dd>
                  </div>
                ))}
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </Card>
  );
}
