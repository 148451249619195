import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { TileSource } from '../apis/rellserver';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

export function getDisplayDate(compareDate: string, displayToday = true, outputDateFormat?: string) {
  const outputFormat = outputDateFormat ?? 'dddd, MMM DD, YYYY';
  const today = dayjs(new Date()).format(outputFormat);
  const transformedCompareDate = dayjs(compareDate, 'YYYY_MM_DD').format(outputFormat);
  if (displayToday) {
    return today === transformedCompareDate ? `(Today) ${today}` : transformedCompareDate;
  }
  return transformedCompareDate;
}

export function getSuitabiltyDisplayText(suitability: number): string {
  if (suitability < 30) {
    return 'Unsuitable';
  }
  if (suitability < 60) {
    return 'Marginal';
  }
  if (suitability < 90) {
    return 'Suitable';
  }
  return 'Optimal';
}

// bootstrap classes
export function getProgressBarColor(suitability: number): string {
  if (suitability < 30) {
    return 'danger';
  }
  if (suitability < 60) {
    return 'warning';
  }
  if (suitability < 90) {
    return 'success';
  }
  return 'success';
}

export interface GradientThresholdColor {
  threshold: number;
  color: string;
}

export const gradientColorMap: GradientThresholdColor[] = [
  { threshold: 0.1, color: '#e9faf4' },
  { threshold: 0.2, color: '#d7f0e8' },
  { threshold: 0.3, color: '#c5e6db' },
  { threshold: 0.4, color: '#b3d9cc' },
  { threshold: 0.5, color: '#87b5a5' },
  { threshold: 0.6, color: '#71a392' },
  { threshold: 0.7, color: '#4f8875' },
  { threshold: 0.8, color: '#2d6d57' },
  { threshold: 0.9, color: '#175b44' },
  { threshold: 1.0, color: '#143e30' },
];

export function getHexCodeForThreshold(colorMap: GradientThresholdColor[], threshold: number): string {
  // Find the color associated with the given threshold
  const matchedColor = colorMap.find((item) => threshold <= item.threshold);

  // Return the hex code or a default value if no match is found
  return matchedColor ? matchedColor.color : '#F2F3F0';
}

export function getSourceIndexOfDate(sources: TileSource[], target: Dayjs): number {
  const today = target.startOf('day');
  const indexToday = sources.findIndex((source) => dayjs(source.date).startOf('day').isSame(today));
  return indexToday < 0 ? sources.length - 1 : indexToday;
}
