import { MapRef } from 'react-map-gl/dist/esm/mapbox/create-ref';
import * as mapboxgl from 'mapbox-gl';
import { GeolocationState } from '../hooks/useGeolocation';

export function flyToGeolocation(map: MapRef<mapboxgl.Map> | undefined, geolocation: GeolocationState, zoom?: number) {
  navigator.permissions.query({ name: 'geolocation' }).then(() => {
    if (!map || !geolocation.isReady || !geolocation.position) {
      return;
    }
    map.flyTo({
      center: {
        lng: geolocation.position.coords.longitude,
        lat: geolocation.position.coords.latitude,
      },
      zoom: zoom ?? map.getZoom(),
      essential: true,
    });
  });
}
