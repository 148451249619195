import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useCombobox } from 'downshift';
import { Form, Image, InputGroup, ListGroup } from 'react-bootstrap';
import {
  PlaceQueryAutocompleteResponse,
  PlaceQueryAutocompletePrediction,
  PlaceDetailsResponseData,
  LatLngLiteral,
} from '@googlemaps/google-maps-services-js';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';
import { CloseButton } from '@mantine/core';
import { ReactSVG } from 'react-svg';
import { LocationMarkers, MapBoundsInfo } from '../MapContainer/MapContainer';
import './SearchBar.scss';
import SearchBarUserInfoButton from '../SearchBarUserInfoButton/SearchBarUserInfoButton';

const LOCAL_RELL_SERVER: string = import.meta.env.VITE_API_URL as string;

export interface GoogleApiRequest {
  sessionToken: string | null;
}

interface PlaceAutocompleteExternalRequest extends MapBoundsInfo, GoogleApiRequest {
  searchTerm: string;
}

interface GetPlaceDetailsExternalRequest extends GoogleApiRequest {
  placeId: string | undefined;
}

interface SearchbarProps {
  currentBounds: MapBoundsInfo;
  setLocationMarkers: (setLocationMarkers: LocationMarkers | null) => void;
}

export default function SearchBar({ currentBounds, setLocationMarkers }: SearchbarProps) {
  const [sessiontoken, setSessiontoken] = useState<string | null>(null);
  const [searchResults, setSearchResults] = useState<PlaceQueryAutocompletePrediction[]>([]);
  const [currentInputValue, setCurrentInputValue] = useState<string>('');

  const debouncedSearch = React.useRef(
    debounce(async (searchString: string) => {
      try {
        console.log('debounce entered');
        let currSessionToken = null;
        if (sessiontoken === null) {
          currSessionToken = uuidv4();
          setSessiontoken(currSessionToken);
        }
        const payload: PlaceAutocompleteExternalRequest = {
          searchTerm: searchString,
          sessionToken: sessiontoken ?? currSessionToken,
          ...currentBounds,
        };

        console.log('debounce: ', payload);

        const response: PlaceQueryAutocompleteResponse = await axios.post(
          `${LOCAL_RELL_SERVER}/places-autocomplete`,
          payload,
        );

        const predictions: PlaceQueryAutocompletePrediction[] = response?.data?.predictions;

        if (Array.isArray(predictions)) {
          setSearchResults(predictions);
        } else {
          setLocationMarkers(null);
        }
      } catch (error) {
        console.error('debounce error', error);
      }
    }, 300),
  ).current;

  useEffect(() => {
    if (currentInputValue === '') {
      return;
    }

    debouncedSearch(currentInputValue)?.catch((err): void => {
      console.log('debounce error', err);
    });
  }, [currentInputValue]);

  const inputRef = useRef<HTMLInputElement>(null);

  const { isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps, selectedItem, selectItem } = useCombobox(
    {
      items: searchResults,
      onInputValueChange: ({ inputValue }) => {
        setCurrentInputValue(inputValue ?? '');
      },
      itemToString: (item) => item?.description ?? '',
      onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
        if (newSelectedItem) {
          (async () => {
            const payload: GetPlaceDetailsExternalRequest = {
              placeId: newSelectedItem.place_id,
              sessionToken: sessiontoken,
            };

            const response: PlaceDetailsResponseData = (
              await axios.post(`${LOCAL_RELL_SERVER}/get-place-details`, payload)
            ).data as PlaceDetailsResponseData;

            setSessiontoken(null);

            console.log('PLACE RESPONSE', response);

            const placeLocation: LatLngLiteral | undefined = response?.result?.geometry?.location;
            const placeId = response?.result?.place_id;
            console.log('PLACE LOCATION', placeLocation);
            if (placeLocation && placeId) {
              const { lng, lat } = placeLocation;
              setLocationMarkers({ markers: [{ lat, lng, placeId }] });
            } else {
              Sentry.captureMessage('some how, the placeid or placelocation is null on a google return', 'error');
              setLocationMarkers(null);
            }

            inputRef.current?.blur();
          })();
        }
      },
    },
  );
  return (
    <>
      <InputGroup size="sm" className="search-bar">
        <div className="search-bar-icon">
          <Image src="assets/img/logo.svg" />
        </div>
        <Form.Control
          {...getInputProps({
            placeholder: 'Search for a location',
            ref: inputRef,
          })}
          className="search-bar-input"
        />
        {currentInputValue !== '' && (
          <CloseButton
            aria-label="Close modal"
            size="md"
            className="rounded-circle clear-search-button"
            style={{
              background: '#efefef',
            }}
            icon={<ReactSVG src="assets/img/cross-button.svg" style={{ fontSize: '12px' }} />}
            onClick={() => {
              setLocationMarkers(null);
              setSearchResults([]);
              selectItem(null);
            }}
          />
        )}
        {currentInputValue === '' && (
          <span
            style={{
              placeSelf: 'center',
            }}
          >
            <SearchBarUserInfoButton />
          </span>
        )}
      </InputGroup>

      <ListGroup {...getMenuProps()} className={`search-results ${!isOpen ? 'hidden' : ''}`}>
        {isOpen &&
          searchResults.map((item, index) => (
            <ListGroup.Item
              key={item.place_id}
              {...getItemProps({
                index,
                item,
                style: {
                  backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
                  fontWeight: selectedItem === item ? 'bold' : 'normal',
                  minWidth: '600px',
                },
              })}
            >
              {item.description}
            </ListGroup.Item>
          ))}
      </ListGroup>
    </>
  );
}
