import { forwardRef } from 'react';
import './UserInfoButton.scss';
import { ChevronDown, LogOut } from 'react-feather';
import { Avatar, Text, Menu, Button } from '@mantine/core';
import classes from './UserInfoButtonMenu.module.scss';
import { useAuth } from '../../hooks/useAuth';

function getUserInitials(firstName: string | undefined, lastName: string | undefined): string {
  if (firstName) {
    let initials = firstName.substring(0, 1).toUpperCase();

    if (lastName) {
      initials += lastName.substring(0, 1).toUpperCase();
    }
    return initials;
  }
  return '??';
}

interface UserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  image: string;
  initials: string;
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ image, initials, ...others }: UserButtonProps, ref) => (
    <Button
      ref={ref}
      size="md"
      leftSection={<Avatar src={image} radius="lg" size={24} />}
      rightSection={<ChevronDown size="1rem" />}
      {...others}
    >
      <Text size="sm" fw={600}>
        {initials}
      </Text>
    </Button>
  ),
);

export default function UserInfoButton() {
  const { user, logout } = useAuth();

  return (
    <div className="user-info-button">
      <Menu offset={0} position="bottom-end" classNames={classes}>
        <Menu.Target>
          <UserButton image="assets/img/user-icon.svg" initials={getUserInitials(user.firstName, user.lastName)} />
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item leftSection={<LogOut size={14} />} onClick={logout}>
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}
