import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { createBrowserRouter, RouterProvider, useOutlet } from 'react-router-dom';
import SuperTokens from 'supertokens-web-js';
import Session from 'supertokens-web-js/recipe/session';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';
import { MantineProvider } from '@mantine/core';
import { AuthProvider } from './hooks/useAuth';
import LoginRequired from './components/LoginRequired/LoginRequired';

import App from './App';
import './bootstrap-overrides.scss';
import './main.scss';
import { LoginModalStep } from './components/LoginModal/LoginModal';

const API_URL: string = import.meta.env.VITE_API_URL as string;
const API_AUTH_BASE_PATH: string = import.meta.env.VITE_API_AUTH_BASE_PATH as string;
const SUPERTOKENS_APP_NAME: string = import.meta.env.VITE_SUPERTOKENS_APP_NAME as string;
const SENTRY_DSN: string = import.meta.env.VITE_SENTRY_DSN as string;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [API_URL],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

SuperTokens.init({
  appInfo: {
    appName: SUPERTOKENS_APP_NAME,
    apiDomain: API_URL,
    apiBasePath: API_AUTH_BASE_PATH,
  },
  recipeList: [Session.init(), EmailPassword.init()],
});

function AuthLayout() {
  const outlet = useOutlet();

  return <AuthProvider>{outlet}</AuthProvider>;
}

const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    children: [
      {
        path: '/',
        element: (
          <LoginRequired>
            <App />
          </LoginRequired>
        ),
      },
      {
        path: '/signup',
        element: <LoginRequired />,
      },
      {
        path: '/login',
        element: <LoginRequired loginModalStep={LoginModalStep.SIGN_IN} />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <MantineProvider>
      <RouterProvider router={router} />
    </MantineProvider>
  </React.StrictMode>,
);
