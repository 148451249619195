import { ReactElement, useEffect, useState } from 'react';
import LoginModal, { LoginModalStep, LoginValues, SignupValues } from '../LoginModal/LoginModal';
import { useAuth } from '../../hooks/useAuth';

interface LoginRequiredProps {
  loginModalStep?: LoginModalStep;
  children?: ReactElement;
}

function LoginRequired({ children, loginModalStep }: Readonly<LoginRequiredProps>) {
  const { user } = useAuth();
  const [invitationCode, setInvitationCode] = useState('');
  const [initialLoginModalStep, setInitialLoginModalStep] = useState<LoginModalStep>(
    loginModalStep ?? LoginModalStep.START,
  );

  useEffect(() => {
    const queryInvitationCode = new URL(window.location.href).searchParams.get('invitationCode');
    const resetPasswordAction = new URL(window.location.href).searchParams.get('action');
    if (queryInvitationCode) {
      setInvitationCode(queryInvitationCode ?? '');
      setInitialLoginModalStep(LoginModalStep.SIGN_UP);
    } else if (resetPasswordAction && resetPasswordAction === 'reset-password') {
      setInitialLoginModalStep(LoginModalStep.RESET_PASSWORD);
    } else {
      setInitialLoginModalStep(LoginModalStep.START);
    }
  }, []);

  const loginInitialValues: LoginValues = {
    email: '',
    password: '',
  };

  const signupInitialValues: SignupValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordCheck: '',
    invitationCode,
  };

  if (!user.isAuthenticated) {
    return (
      <LoginModal
        loginInitialValues={loginInitialValues}
        signupInitialValues={signupInitialValues}
        initialLoginModalStep={initialLoginModalStep}
        key={invitationCode}
      />
    );
  }
  return children;
}

export default LoginRequired;
