import { forwardRef } from 'react';
import './SearchBarUserInfoButton.scss';
import { LogOut } from 'react-feather';
import { Text, Menu, UnstyledButton } from '@mantine/core';
import { useAuth } from '../../hooks/useAuth';

function getUserInitials(firstName: string | undefined, lastName: string | undefined): string {
  if (firstName) {
    let initials = firstName.substring(0, 1).toUpperCase();

    if (lastName) {
      initials += lastName.substring(0, 1).toUpperCase();
    }
    return initials;
  }
  return '??';
}

interface SearchBarUserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  initials: string;
}

const UserButton = forwardRef<HTMLButtonElement, SearchBarUserButtonProps>(
  ({ initials, ...others }: SearchBarUserButtonProps, ref) => (
    <UnstyledButton
      ref={ref}
      size="md"
      {...others}
      className="rounded-circle"
      style={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
      }}
      styles={{
        root: {
          height: '28px',
          width: '28px',
          border: '1.75px #000 solid',
        },
      }}
    >
      <Text
        size="sm"
        fw={600}
        style={{
          placeSelf: 'center',
          fontSize: '13px',
          fontWeight: '600',
        }}
      >
        {initials}
      </Text>
    </UnstyledButton>
  ),
);

export default function SearchBarUserInfoButton() {
  const { user, logout } = useAuth();

  return (
    <div className="search-bar-user-info-button">
      <Menu offset={0} position="bottom-end">
        <Menu.Target>
          <UserButton initials={getUserInitials(user.firstName, user.lastName)} />
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item leftSection={<LogOut size={14} />} onClick={logout}>
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}
