import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { ViewState } from 'react-map-gl';
import { MapLayerMouseEvent } from 'maplibre-gl';
import { ToastContainer } from 'react-toastify';
import { MapProvider } from 'react-map-gl/maplibre';

import MapContainer, { ClickedFeature, LocationMarkers, MapBoundsInfo } from './components/MapContainer/MapContainer';
import FeatureDetails from './components/FeatureDetails/FeatureDetails';
import SearchBar from './components/SearchBar/SearchBar';
import TimeScrubber from './components/TimeScrubber/TimeScrubber';
import './App.scss';

import { DATE_FORMAT } from './lib/models/consts';
import { HistoricalDisplayItem, SpeciesSelectorDropdownItem } from './lib/models/interfaces';
import { SuitabilityHistoryGraphInterface } from './components/SuitabilityHistoryGraph/SuitabilityHistoryGraph';
import SpeciesSelector from './components/SpeciesSelector/SpeciesSelector';
import ActionButtonDrawer from './components/ActionButtonDrawer/ActionButtonDrawer';
import UserInfoButton from './components/UserInfoButton/UserInfoButton';
import { getTileSources, TileSource } from './apis/rellserver';

import '@mantine/core/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './components/MapOverlay/MapOverlay.scss';
import { getSourceIndexOfDate } from './utils/utils';

function App() {
  const [clickedFeature, setClickedFeature] = useState<ClickedFeature | null>(null);

  const [currentBounds, setCurrentBounds] = useState<MapBoundsInfo>({
    radius: 1000,
    center: {
      lat: 40,
      lng: 400,
    },
  });

  const speciesList: SpeciesSelectorDropdownItem[] = [
    {
      speciesName: 'morel',
      displayName: 'Morel',
      disabled: false,
    },
    {
      speciesName: 'cantharellus',
      displayName: 'Chanterelles',
      disabled: false,
    },
    {
      speciesName: 'king-bolete',
      displayName: 'King Bolete',
      disabled: false,
    },
  ];

  const [currentSource, setCurrentSource] = useState<string>();
  const [tileSources, setTileSources] = useState<TileSource[]>([]);
  const [locationMarkers, setLocationMarkers] = useState<LocationMarkers | null>(null);
  const [suitabilityHistory, setSuitabilityHistory] = useState<SuitabilityHistoryGraphInterface | null>(null);
  const [selectedSpecies, setSelectedSpecies] = useState<SpeciesSelectorDropdownItem>(speciesList[0]);
  const [historicalDaysDisplay, setHistoricalDaysDisplay] = useState<HistoricalDisplayItem>({
    value: '12-months',
    unit: 'month',
    duration: 12,
    display: 'Last 12 Months',
  });
  const [currentDisplayedDate, setCurrentDisplayedDate] = useState<string>(dayjs().format(DATE_FORMAT));

  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setScreenWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = screenWidth <= 768;

  const [mapViewState, setMapViewState] = useState<ViewState>({
    longitude: -76.51,
    latitude: 42.7,
    zoom: isMobile ? 5 : 7,
  } as ViewState);
  const [clickedPoint, setClickedPoint] = useState<MapLayerMouseEvent | null>(null);
  const [currentTileSourceIndex, setCurrentTileSourceIndex] = useState<number>(0);

  useEffect(() => {
    // todo: cache results
    getTileSources({ modelName: selectedSpecies.speciesName })
      .then((sources) => {
        const indexToday = getSourceIndexOfDate(sources, dayjs());
        if (currentSource === null) {
          setCurrentSource(sources[indexToday]?.url);
        }

        setCurrentTileSourceIndex(indexToday);
        setTileSources(sources);
      })
      .catch((err) => {
        console.log(err);
        // todo: sentry alarm
        // todo: show unavailable error
      });
  }, [selectedSpecies]);

  return (
    <MapProvider>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="dark"
        style={{ zIndex: 36 }}
      />
      <MapContainer
        currentSource={currentSource}
        setClickedFeature={setClickedFeature}
        setCurrentBounds={setCurrentBounds}
        setSuitabilityHistory={setSuitabilityHistory}
        locationMarkers={locationMarkers}
        setLocationMarkers={setLocationMarkers}
        historicalDaysDisplay={historicalDaysDisplay}
        selectedSpecies={selectedSpecies}
        currentDisplayedDate={currentDisplayedDate}
        clickedFeature={clickedFeature}
        mapViewState={mapViewState}
        setMapViewState={setMapViewState}
        clickedPoint={clickedPoint}
        setClickedPoint={setClickedPoint}
      />
      <div className="mapoverlay-container">
        <div className="search-bar-container">
          <SearchBar currentBounds={currentBounds} setLocationMarkers={setLocationMarkers} />
        </div>
        <div className="corner-panel">
          <div className="selector-container">
            <SpeciesSelector
              speciesList={speciesList}
              selectedSpecies={selectedSpecies}
              setSelectedSpecies={setSelectedSpecies}
            />
            <TimeScrubber
              currentTileSourceIndex={currentTileSourceIndex}
              setCurrentTileSourceIndex={setCurrentTileSourceIndex}
              tileSources={tileSources}
              setCurrentSource={setCurrentSource}
              setCurrentDisplayDate={setCurrentDisplayedDate}
            />
          </div>
          <UserInfoButton />
        </div>
        {clickedFeature && clickedFeature.featureLayers.length > 0 && (
          <FeatureDetails
            clickedFeature={clickedFeature}
            setClickedFeature={setClickedFeature}
            suitabilityHistory={suitabilityHistory}
            setHistoricalDaysDisplay={setHistoricalDaysDisplay}
            historicalDaysDisplay={historicalDaysDisplay}
            currentDisplayedDate={currentDisplayedDate}
            isMobile={isMobile}
            setClickedPoint={setClickedPoint}
          />
        )}
        <ActionButtonDrawer />
      </div>
    </MapProvider>
  );
}

export default App;
