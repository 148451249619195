import { Map as MapLibreMap, MapEvent, MapRef, Marker, Popup } from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';
import { Layer, Source, useMap, ViewState } from 'react-map-gl';
import { FillLayer, FillPaint, LineLayer, LinePaint } from 'mapbox-gl';
import {
  MapLayerMouseEvent,
  MapGeoJSONFeature,
  LngLatBounds,
  FeatureIdentifier,
  StyleSpecification,
  LngLatLike,
  LayerSpecification,
  SymbolLayerSpecification,
} from 'maplibre-gl';
import { useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { ReactSVG } from 'react-svg';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { darken } from 'color-toolkit';
import * as mapStyleJson from './styles/mapStyle.json';

import './MapContainer.scss';
import { SuitabilityHistoryGraphInterface } from '../SuitabilityHistoryGraph/SuitabilityHistoryGraph';
import { GetSuitabilityHistoryRequest, getSuitabilityHistory } from '../../apis/rellserver';
import { HistoricalDisplayItem, SpeciesSelectorDropdownItem } from '../../lib/models/interfaces';
import { usePmtilesProtocol } from '../../hooks/usePmtilesProtocol';

import { RellToastMessage } from '../../lib/models/consts';
import { flyToGeolocation } from '../../utils/maplibre';
import { useGeolocation } from '../../hooks/useGeolocation';

export interface FeatureLayer {
  layerId: string;
  properties: Record<string, string>;
}

export interface ClickedFeature {
  latitude: number;
  longitude: number;
  addressSnippet?: string;
  featureLayers: FeatureLayer[];
}

export interface LocationMarkers {
  markers: LocationMarker[];
  isFromMapClick?: boolean;
}

export interface LocationMarker {
  lat: number;
  lng: number;
  placeId?: string;
  // TO DO: figure out what else to display
}

export interface MapBoundsInfo {
  radius: number;
  center: {
    lat: number;
    lng: number;
  };
}

export interface ReverseGeocodeExternalRequest {
  lat: number;
  lng: number;
}

export interface FeatureIdentifierStorage {
  identifier: FeatureIdentifier;
  featureLayers: FeatureLayer[];
}

function convertHexList(colorList: (string | number)[], percent: number): unknown[] {
  const darkenedColorList: (string | number)[] = Object.assign([], colorList);
  for (let i = 0; i < colorList.length; i += 1) {
    if (typeof colorList[i] === 'string') {
      darkenedColorList[i] = darken(darkenedColorList[i] as string, percent);
    }
  }
  return darkenedColorList;
}

const probabilityHexCodes: (string | number)[] = [
  '#e9faf4',
  0.1,
  '#d7f0e8',
  0.2,
  '#c5e6db',
  0.3,
  '#b3d9cc',
  0.4,
  '#87b5a5',
  0.5,
  '#71a392',
  0.6,
  '#4f8875',
  0.7,
  '#2d6d57',
  0.8,
  '#175b44',
  0.9,
  '#143e30',
  1.0,
  '#143e30',
];

const defaultPaint: FillPaint = {
  'fill-color': [
    'case',
    ['to-boolean', ['feature-state', 'click']],
    '#AFE1AF',
    ['to-boolean', ['feature-state', 'hover']],
    ['step', ['get', 'probability'], ...convertHexList(probabilityHexCodes, 0.15)],
    ['step', ['get', 'probability'], ...probabilityHexCodes],
  ],
  'fill-opacity': 0.75,
  'fill-outline-color': '#143E30',
};

const linePaint: LinePaint = {
  'line-color': '#143E30',
  'line-width': 3,
  'line-opacity': 0.8,
};

const layers: (FillLayer | LineLayer)[] = [
  {
    id: 'layer1',
    type: 'fill',
    source: 'ny',
    'source-layer': 'h3_4',
    paint: { ...defaultPaint },
    maxzoom: 6,
  },
  {
    id: 'layer2',
    type: 'fill',
    source: 'ny',
    'source-layer': 'h3_5',
    paint: { ...defaultPaint },
    minzoom: 6,
    maxzoom: 8,
  },
  {
    id: 'layer3',
    type: 'fill',
    source: 'ny',
    paint: { ...defaultPaint },
    minzoom: 8,
    maxzoom: 13,
    'source-layer': 'h3_6',
  },
  {
    id: 'layer4',
    type: 'fill',
    source: 'ny',
    paint: { ...defaultPaint, 'fill-opacity': 0.3 },
    minzoom: 13,
    'source-layer': 'h3_6',
  },
  {
    id: 'layer4-line',
    type: 'line',
    source: 'ny',
    paint: { ...linePaint },
    minzoom: 13,
    'source-layer': 'h3_6',
  },
];

function mapZoomToLayerResolution(zoom: number) {
  if (zoom < 6) {
    return 4;
  }
  if (zoom > 6 || zoom < 8) {
    return 5;
  }
  return 6;
}

interface MapContainerProps {
  setClickedFeature: (clickedFeature: ClickedFeature) => void;
  setCurrentBounds: (currentBounds: MapBoundsInfo) => void;
  setSuitabilityHistory: (suitabilityHistory: SuitabilityHistoryGraphInterface | null) => void;
  historicalDaysDisplay: HistoricalDisplayItem;
  currentSource: string | undefined;
  locationMarkers: LocationMarkers | null;
  selectedSpecies: SpeciesSelectorDropdownItem;
  currentDisplayedDate: string;
  clickedFeature: ClickedFeature | null;
  setLocationMarkers: (setLocationMarkers: LocationMarkers | null) => void;
  mapViewState: ViewState;
  setMapViewState: (mapViewState: ViewState) => void;
  clickedPoint: MapLayerMouseEvent | null;
  setClickedPoint: (clickedPoint: MapLayerMouseEvent | null) => void;
}

export default function MapContainer({
  currentSource,
  setClickedFeature,
  setCurrentBounds,
  locationMarkers,
  setSuitabilityHistory,
  historicalDaysDisplay,
  selectedSpecies,
  currentDisplayedDate,
  clickedFeature,
  mapViewState,
  setMapViewState,
  clickedPoint,
  setClickedPoint,
}: MapContainerProps) {
  const mapRef = useRef<MapRef>(null); // in case we need it
  const [mouseCursor, setMouseCursor] = useState<string>('default');
  const [featureIdentifier, setFeatureIdentifer] = useState<Map<number, FeatureIdentifierStorage>>(
    new Map<number, FeatureIdentifierStorage>(),
  );
  const [contextMenu, setContextMenu] = useState<MapLayerMouseEvent | null>(null);
  // let hoveredFeature: FeatureIdentifier | null = null;
  const hoveredFeature = useRef<FeatureIdentifier | null>(null);
  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);

  const geolocation = useGeolocation();
  const { default: map } = useMap();

  usePmtilesProtocol();

  function parseBounds(bounds: LngLatBounds): MapBoundsInfo {
    if (!bounds) {
      return {} as MapBoundsInfo;
    }

    const mapBoundsInfo: MapBoundsInfo = {
      radius: bounds.getCenter().distanceTo(bounds.getNorthEast()),
      center: {
        lat: bounds.getCenter().lat,
        lng: bounds.getCenter().lng,
      },
    };

    return mapBoundsInfo;
  }

  function getAdjustedZoom(): string {
    return mapZoomToLayerResolution(mapViewState?.zoom).toString();
  }

  function findLatLongCenter(coordinates: LocationMarker[]): LocationMarker | null {
    if (!coordinates || coordinates.length === 0 || !Array.isArray(coordinates)) {
      return null; // Return null or handle the empty case as needed
    }

    // Calculate average latitude and longitude
    const totalCoords = coordinates.length;
    const sumLat = coordinates.reduce((acc, coord) => acc + coord.lat, 0);
    const sumLng = coordinates.reduce((acc, coord) => acc + coord.lng, 0);

    const centerLat = sumLat / totalCoords;
    const centerLng = sumLng / totalCoords;

    return { lat: centerLat, lng: centerLng, placeId: 'temp' };
  }

  function mapFeatureProperties(feature: MapGeoJSONFeature): FeatureLayer[] {
    return [
      {
        layerId: feature?.layer.id,
        properties: { ...feature?.properties },
      },
    ];
  }

  function updateFeatureIdentifiers(features: MapGeoJSONFeature[] | undefined): void {
    features?.forEach((feature) => {
      const currentFeature: FeatureIdentifierStorage = {
        identifier: {
          source: feature.source,
          sourceLayer: feature.sourceLayer,
          id: feature.id,
        } as FeatureIdentifier,
        featureLayers: mapFeatureProperties(feature),
      };
      setFeatureIdentifer((_map) => new Map(_map.set(feature.id as number, currentFeature)));
      mapRef?.current?.setFeatureState(currentFeature.identifier, {
        click: true,
      });

      setClickedFeature({
        ...clickedFeature,
        featureLayers: mapFeatureProperties(feature),
      } as ClickedFeature);
    });
  }

  function handleClickToCopy(value: string) {
    navigator.clipboard.writeText(value);
    toast(RellToastMessage.COPY_TO_CLIPBOARD);
  }

  function getBeforeId(): string {
    const mapLayers: LayerSpecification[] | undefined = mapRef?.current?.getStyle()?.layers;
    const placeId = mapLayers?.find((x) => (x as SymbolLayerSpecification)['source-layer'] === 'boundary')?.id ?? '';

    return placeId;
  }

  useEffect(() => {
    if (clickedPoint) {
      const request: GetSuitabilityHistoryRequest = {
        startDate: currentDisplayedDate,
        species: selectedSpecies.speciesName,
        duration: historicalDaysDisplay.duration,
        unit: historicalDaysDisplay.unit,
        zoom: getAdjustedZoom(),
        lat: clickedPoint.lngLat.lat.toString(),
        lng: clickedPoint.lngLat.lng.toString(),
      };

      getSuitabilityHistory(request).then((response) => {
        setSuitabilityHistory(response.data);
      });
    }
  }, [currentSource]);

  useEffect(() => {
    if (locationMarkers && !locationMarkers.isFromMapClick) {
      const centerCoord: LocationMarker | null = findLatLongCenter(locationMarkers.markers);
      if (centerCoord) {
        const clickedLocation: LngLatLike = {
          lng: centerCoord.lng,
          lat: centerCoord.lat,
        };
        mapRef?.current?.flyTo({
          center: clickedLocation,
          essential: true,
        });
      }
    }
  }, [locationMarkers]);

  useEffect(() => {
    if (clickedFeature) {
      const request: GetSuitabilityHistoryRequest = {
        startDate: currentDisplayedDate,
        species: selectedSpecies.speciesName,
        duration: historicalDaysDisplay.duration,
        unit: historicalDaysDisplay.unit,
        zoom: getAdjustedZoom(),
        lat: clickedFeature.latitude.toString(),
        lng: clickedFeature.longitude.toString(),
      };

      getSuitabilityHistory(request).then((response) => {
        setSuitabilityHistory(response.data);
      });
    }
  }, [historicalDaysDisplay]);

  useEffect(() => {
    if (!clickedPoint) {
      featureIdentifier.forEach((value: FeatureIdentifierStorage) => {
        mapRef?.current?.removeFeatureState(value.identifier, 'click');
        mapRef?.current?.removeFeatureState(value.identifier, 'hover');
      });
      setFeatureIdentifer(new Map<number, FeatureIdentifierStorage>());
    }
  }, [clickedPoint]);

  useEffect(() => {
    if (isMapLoaded && geolocation.isReady) {
      flyToGeolocation(map, geolocation);
    }
  }, [isMapLoaded, geolocation]);

  return (
    <div className="map-container">
      <MapLibreMap
        {...mapViewState}
        ref={mapRef}
        onMove={(event) => setMapViewState(event.viewState)}
        cursor={mouseCursor}
        minZoom={2.5}
        onMouseMove={(event: MapLayerMouseEvent) => {
          if (event?.features?.length && event.features.length > 0) {
            if (hoveredFeature.current) {
              mapRef?.current?.removeFeatureState(hoveredFeature.current, 'hover');
            }

            hoveredFeature.current = {
              source: event.features[0].source,
              sourceLayer: event.features[0].sourceLayer,
              id: event.features[0].id,
            } as FeatureIdentifier;

            mapRef?.current?.setFeatureState(hoveredFeature.current, {
              hover: true,
            });
          }
        }}
        onMouseLeave={() => {
          if (hoveredFeature.current) {
            mapRef?.current?.removeFeatureState(hoveredFeature.current, 'hover');
          }
          hoveredFeature.current = null;
          setMouseCursor('default');
        }}
        onDragStart={() => {
          setMouseCursor('move');
        }}
        onZoomEnd={(mapEvent: MapEvent) => {
          setCurrentBounds(parseBounds(mapEvent.target.getBounds()));
        }}
        onDragEnd={(mapEvent: MapEvent) => {
          setMouseCursor('default');
          setCurrentBounds(parseBounds(mapEvent.target.getBounds()));
        }}
        onLoad={(mapEvent: MapEvent) => {
          setIsMapLoaded(true);
          console.log('Map loaded.');
          setCurrentBounds(parseBounds(mapEvent.target.getBounds()));
        }}
        onMouseEnter={() => {
          if (hoveredFeature.current) {
            mapRef?.current?.removeFeatureState(hoveredFeature.current, 'hover');
          }
          hoveredFeature.current = null;
          setMouseCursor('pointer');
        }}
        onContextMenu={(mapEvent: MapLayerMouseEvent) => {
          setContextMenu(mapEvent);
        }}
        interactiveLayerIds={layers.filter((x) => x.type === 'fill').map((layer) => layer.id)}
        mapStyle={mapStyleJson as StyleSpecification}
        onSourceData={() => {
          if (clickedPoint) {
            const actualPoint = mapRef?.current?.project(clickedPoint.lngLat);
            const foundFeatures = mapRef?.current?.queryRenderedFeatures(actualPoint, {
              layers: layers.filter((x) => x.type === 'fill').map((layer) => layer.id),
            });

            updateFeatureIdentifiers(foundFeatures);
          }
        }}
        onClick={async (event: MapLayerMouseEvent) => {
          // ok so this just has to be used, for some reason it's not being marshalled properly
          const features: MapGeoJSONFeature[] = event.features!;
          if (features.length === 0) {
            return;
          }

          if (featureIdentifier.size > 0) {
            featureIdentifier.forEach((value: FeatureIdentifierStorage) => {
              mapRef?.current?.removeFeatureState(value.identifier, 'click');
              mapRef?.current?.removeFeatureState(value.identifier, 'hover');
            });

            setFeatureIdentifer(new Map<number, FeatureIdentifierStorage>());
          }

          const suitabilityHistoryRequest: GetSuitabilityHistoryRequest = {
            startDate: currentDisplayedDate,
            species: selectedSpecies.speciesName,
            duration: historicalDaysDisplay.duration,
            unit: historicalDaysDisplay.unit,
            zoom: getAdjustedZoom(),
            lat: event.lngLat.lat.toString(),
            lng: event.lngLat.lng.toString(),
          };

          const suitabilityHistoyResponse: AxiosResponse<SuitabilityHistoryGraphInterface> =
            await getSuitabilityHistory(suitabilityHistoryRequest);

          setSuitabilityHistory(suitabilityHistoyResponse.data);

          const foundFeatures = mapRef?.current?.queryRenderedFeatures(event.point, {
            layers: layers.map((layer) => layer.id),
          });

          updateFeatureIdentifiers(foundFeatures);

          setClickedPoint(event);
          setClickedFeature({
            latitude: event.lngLat.lat,
            longitude: event.lngLat.lng,
            featureLayers: mapFeatureProperties(features[0]),
          } as ClickedFeature);
        }}
      >
        {locationMarkers?.markers.map((marker) => (
          <Marker longitude={marker.lng} latitude={marker.lat} key={marker.placeId} anchor="bottom" scale={1}>
            <ReactSVG
              src="assets/img/location-pin.svg"
              style={{ width: '30px' }} // todo: fix sizing
            />
          </Marker>
        ))}
        {geolocation.position && (
          <Marker
            longitude={geolocation.position.coords.longitude}
            latitude={geolocation.position.coords.latitude}
            anchor="bottom"
            scale={1}
          >
            <ReactSVG src="assets/img/current-location-marker.svg" style={{ fontSize: '48px' }} />
          </Marker>
        )}
        {contextMenu && (
          <Popup
            anchor="bottom"
            longitude={Number(contextMenu.lngLat.lng)}
            latitude={Number(contextMenu.lngLat.lat)}
            closeButton={false}
            onClose={() => setContextMenu(null)}
            className="context-menu"
          >
            <div className="info-wrapper">
              <div>
                <strong>Coordinates at this point: </strong>
              </div>
              <div style={{ whiteSpace: 'nowrap' }}>
                {_.round(Number(contextMenu.lngLat.lng), 4)}, {_.round(Number(contextMenu.lngLat.lat), 4)}
              </div>
              <button
                type="button"
                className="link"
                onClick={() => handleClickToCopy(`${contextMenu.lngLat.lng}, ${contextMenu.lngLat.lat}`)}
              >
                Copy
              </button>
            </div>
          </Popup>
        )}
        {currentSource && (
          <Source id="ny" type="vector" url={currentSource} promoteId="probability">
            {layers.map((layerProps) => (
              <Layer key={layerProps.id} {...layerProps} beforeId={getBeforeId()} />
            ))}
          </Source>
        )}
      </MapLibreMap>
    </div>
  );
}
