import { Col, Form, Image, Modal, Row } from 'react-bootstrap';
import { useState } from 'react';
import * as yup from 'yup';
import {
  signIn,
  signUp,
  doesEmailExist,
  sendPasswordResetEmail,
  submitNewPassword,
} from 'supertokens-web-js/recipe/emailpassword';
import { Formik, FormikProps } from 'formik';
import { ArrowLeft } from 'react-feather';
import { v4 as uuidv4 } from 'uuid';
import { toast, ToastContainer } from 'react-toastify';
import { UnstyledButton, Button } from '@mantine/core';
import { useAuth } from '../../hooks/useAuth';
import 'react-toastify/dist/ReactToastify.css';

import './LoginModal.scss';
import { RellToastMessage } from '../../lib/models/consts';

export interface SignupValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordCheck: string;
  invitationCode: string;
}

export interface LoginValues {
  email: string;
  password: string;
}

export interface ForgotPasswordValues {
  email: string;
}

export interface ResetPasswordValues {
  password: string;
  passwordCheck: string;
}

export interface LoginModalProps {
  signupInitialValues: SignupValues;
  loginInitialValues: LoginValues;
  initialLoginModalStep: LoginModalStep;
}

export interface SubmitResult {
  message: string;
  resultType: SubmitResultType;
}

export enum SubmitResultType {
  ERROR = 'text-danger',
  SUCCESS = 'text-success',
  WARN = 'text-warning',
  INFO = 'text-info',
}

export enum LoginModalStep {
  START = 'start',
  SIGN_UP = 'signup',
  SIGN_IN = 'signin',
  T_AND_C = 'terms_and_conditions',
  PRIVACY = 'privacy_policy',
  FORGOT_PASSWORD = 'forgot_password',
  FORGOT_PASSWORD_CONFIRM = 'forgot_password_confirm',
  RESET_PASSWORD = 'reset_password',
}

const APP_URL_LINK = <a href={`https://${window.location.hostname}`}>{`https://${window.location.hostname}`}/</a>;

function LoginModal({ signupInitialValues, loginInitialValues, initialLoginModalStep }: LoginModalProps) {
  // todo: swap out with mantine useForm

  const { login } = useAuth();

  const [submitResults, setSubmitResults] = useState<SubmitResult[] | undefined>([]);
  const [modalStep, setModalStep] = useState<LoginModalStep>(initialLoginModalStep);
  const [savedEmail, setSavedEmail] = useState<string>();

  const checkEmail = async function checkEmail(email: string): Promise<boolean> {
    const response = await doesEmailExist({
      email,
    });

    return !response.doesExist;
  };

  const signupSchema = yup.object().shape({
    firstName: yup.string().required('First Name is a required field.'),
    lastName: yup.string().required('Last Name is a required field.'),
    email: yup
      .string()
      .email('Must be a valid email')
      .required('Email is a required field.')
      .test('email-unique', 'Email already exists, perhaps you could sign in?', (value) => checkEmail(value)),
    password: yup
      .string()
      .required('Password is a required field.')
      .min(8, 'Your password is too short.')
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(.{8,})$/,
        'Please choose a stronger password. Try a mix of letters, numbers, and symbols.',
      ),
    passwordCheck: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords do not match.')
      .required('Confirm password is a required field.'),
    invitationCode: yup.string().required('Invitation Code is a required field.'),
  });

  const signinSchema = yup.object().shape({
    email: yup.string().email('Must be a valid email').required('Email is a required field.'),
    password: yup.string().required('Password is a required field.'),
  });

  const forgotPasswordSchema = yup.object().shape({
    email: yup.string().email('Must be a valid email').required('Email is a required field.'),
  });

  const resetPasswordSchema = yup.object().shape({
    password: yup
      .string()
      .required('Password is a required field.')
      .min(8, 'Your password is too short.')
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(.{8,})$/,
        'Please choose a stronger password. Try a mix of letters, numbers, and symbols.',
      ),
    passwordCheck: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords do not match.')
      .required('Confirm password is a required field.'),
  });

  const handleSignUp = async (formikProps: FormikProps<SignupValues>) => {
    const { values, submitForm, setSubmitting } = formikProps;
    setSubmitting(true);
    setSubmitResults(undefined);
    submitForm();

    try {
      const response = await signUp({
        formFields: [
          {
            id: 'email',
            value: values.email,
          },
          {
            id: 'password',
            value: values.password,
          },
          {
            id: 'firstName',
            value: values.firstName,
          },
          {
            id: 'lastName',
            value: values.lastName,
          },
          {
            id: 'invitationCode',
            value: values.invitationCode,
          },
        ],
      });

      if (response.status === 'FIELD_ERROR') {
        setSubmitResults(
          response.formFields.map((formField) => ({ message: formField.error, resultType: SubmitResultType.ERROR })),
        );
        return;
      }
      if (response.status === 'SIGN_UP_NOT_ALLOWED') {
        setSubmitResults([
          { message: 'Sign ups are currently closed. Please try again later!', resultType: SubmitResultType.ERROR },
        ]);
        return;
      }

      setSavedEmail(response.user.emails[0]);
      toast(RellToastMessage.SIGNUP_SUCCESSFUL);
      setModalStep(LoginModalStep.SIGN_IN);
    } catch (err) {
      setSubmitResults([{ message: (err as Error).message, resultType: SubmitResultType.ERROR }]);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSignIn = async (formikProps: FormikProps<LoginValues>) => {
    const { values, setSubmitting } = formikProps;
    setSubmitting(true);
    setSubmitResults(undefined);

    try {
      const response = await signIn({
        formFields: [
          {
            id: 'email',
            value: values.email,
          },
          {
            id: 'password',
            value: values.password,
          },
        ],
      });

      if (response.status === 'FIELD_ERROR') {
        setSubmitResults(
          response.formFields.map((formField) => ({ message: formField.error, resultType: SubmitResultType.ERROR })),
        );
        return;
      }
      if (response.status === 'WRONG_CREDENTIALS_ERROR' || response.status === 'SIGN_IN_NOT_ALLOWED') {
        setSubmitResults([{ message: 'The email or password are incorrect.', resultType: SubmitResultType.ERROR }]);
        return;
      }
      login();
    } catch (err) {
      setSubmitResults([{ message: (err as Error).message, resultType: SubmitResultType.ERROR }]);
    } finally {
      setSubmitting(false);
    }
  };

  const handleForgotPassword = async (formikProps: FormikProps<ForgotPasswordValues>) => {
    const { values, setSubmitting } = formikProps;
    setSubmitting(true);
    setSubmitResults(undefined);

    try {
      const response = await sendPasswordResetEmail({
        formFields: [
          {
            id: 'email',
            value: values.email,
          },
        ],
      });

      if (response.status === 'FIELD_ERROR') {
        // one of the input formFields failed validaiton
        setSubmitResults(
          response.formFields.map((formField) => ({ message: formField.error, resultType: SubmitResultType.ERROR })),
        );
      } else if (response.status === 'PASSWORD_RESET_NOT_ALLOWED') {
        // this can happen due to automatic account linking. Please read our account linking docs
        setSubmitResults([
          {
            message: 'Password linking not allowed.',
            resultType: SubmitResultType.ERROR,
          },
        ]);
      } else {
        // reset password email sent.
        setModalStep(LoginModalStep.FORGOT_PASSWORD_CONFIRM);
      }
    } catch (err) {
      setSubmitResults([{ message: (err as Error).message, resultType: SubmitResultType.ERROR }]);
    } finally {
      setSubmitting(false);
    }
  };

  const handleResetPassword = async (formikProps: FormikProps<ResetPasswordValues>) => {
    const { values, setSubmitting } = formikProps;
    setSubmitting(true);

    try {
      const response = await submitNewPassword({
        formFields: [
          {
            id: 'password',
            value: values.passwordCheck,
          },
        ],
      });

      if (response.status === 'FIELD_ERROR') {
        setSubmitResults(
          response.formFields.map((formField) => ({ message: formField.error, resultType: SubmitResultType.ERROR })),
        );
      } else if (response.status === 'RESET_PASSWORD_INVALID_TOKEN_ERROR') {
        // the password reset token in the URL is invalid, expired, or already consumed
        setSubmitResults([
          {
            message: 'Password reset link expired, please initiate another flow.',
            resultType: SubmitResultType.ERROR,
          },
        ]);
      } else {
        setSubmitResults([
          {
            message: 'Password reset successful, please login with your new password.',
            resultType: SubmitResultType.SUCCESS,
          },
        ]);
        setModalStep(LoginModalStep.SIGN_IN);
      }
    } catch (err) {
      setSubmitResults([{ message: (err as Error).message, resultType: SubmitResultType.ERROR }]);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal show backdrop="static" keyboard={false}>
      <Modal.Body>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          theme="dark"
          style={{ zIndex: 36 }}
        />
        {modalStep === LoginModalStep.START && (
          <div className="rell-modal-body" style={{ minHeight: '580px' }}>
            <div className="modal-header">
              <Image src="assets/img/logo.svg" />
              <h4>Welcome to Rell</h4>
              <p>Forage with confidence</p>
            </div>
            <div className="d-grid gap-2">
              <Button
                color="#18352a"
                onClick={() => {
                  setModalStep(LoginModalStep.SIGN_UP);
                }}
              >
                Get started
              </Button>
              <Button
                color="#d9d9d9"
                styles={{
                  label: {
                    color: '#000000',
                  },
                }}
                onClick={() => {
                  setModalStep(LoginModalStep.SIGN_IN);
                }}
              >
                I already have an account
              </Button>
            </div>
          </div>
        )}
        {modalStep === LoginModalStep.SIGN_IN && (
          <div className="rell-modal-body">
            <ArrowLeft
              style={{ width: '24px', height: '24px' }}
              className="back-button"
              onClick={() => {
                setModalStep(LoginModalStep.START);
                setSubmitResults(undefined);
              }}
            />
            <div className="modal-header">
              <h4>Login</h4>
              <p>Welcome back to Rell!</p>
            </div>
            <Formik
              validationSchema={signinSchema}
              onSubmit={() => {}}
              initialValues={{ ...loginInitialValues, email: savedEmail ?? '' }}
            >
              {(formikProps) => {
                const { values, touched, errors, handleBlur, handleChange, isSubmitting } = formikProps;
                return (
                  <Form noValidate style={{ marginTop: '1em' }}>
                    <Form.Group className="mb-3" controlId="form.email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.email && !errors.email}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-4" controlId="form.password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.password && !errors.password}
                        isInvalid={!!errors.password}
                      />
                      <UnstyledButton
                        styles={{
                          root: {
                            fontSize: '13px',
                            color: 'rgba(var(--bs-link-color-rgb))',
                            marginTop: '1em',
                          },
                        }}
                        onClick={() => {
                          setModalStep(LoginModalStep.FORGOT_PASSWORD);
                        }}
                      >
                        Forgot Password?
                      </UnstyledButton>
                    </Form.Group>

                    {submitResults?.map((submitResult) => (
                      <div key={uuidv4()} className={`result-message ${submitResult.resultType}`}>
                        {submitResult.message}
                      </div>
                    ))}

                    <div className="d-grid gap-2" style={{ marginTop: '3em' }}>
                      <Button
                        color="#18352a"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        onClick={() => handleSignIn(formikProps)}
                      >
                        Sign In
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
        {modalStep === LoginModalStep.FORGOT_PASSWORD && (
          <div className="rell-modal-body sm">
            <ArrowLeft
              style={{ width: '24px', height: '24px' }}
              className="back-button"
              onClick={() => {
                setModalStep(LoginModalStep.SIGN_IN);
                setSubmitResults(undefined);
              }}
            />
            <div className="modal-header">
              <h4>Forgot Password</h4>
              <p className="forgot-password">To reset your password, please enter your email</p>
            </div>
            <Formik
              validationSchema={forgotPasswordSchema}
              onSubmit={() => {}}
              initialValues={{ email: savedEmail ?? '' }}
            >
              {(formikProps) => {
                const { values, touched, errors, handleBlur, handleChange, isSubmitting } = formikProps;
                return (
                  <Form noValidate style={{ marginTop: '1em' }}>
                    <Form.Group className="mb-3" controlId="form.email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.email && !errors.email}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>

                    {submitResults?.map((submitResult) => (
                      <div key={uuidv4()} className={`result-message ${submitResult.resultType}`}>
                        {submitResult.message}
                      </div>
                    ))}

                    <div className="d-grid gap-2" style={{ marginTop: '3em' }}>
                      <Button
                        color="#18352a"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        onClick={() => handleForgotPassword(formikProps)}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
        {modalStep === LoginModalStep.FORGOT_PASSWORD_CONFIRM && (
          <div className="rell-modal-body">
            <ArrowLeft
              style={{ width: '24px', height: '24px' }}
              className="back-button"
              onClick={() => {
                setModalStep(LoginModalStep.SIGN_IN);
                setSubmitResults(undefined);
              }}
            />
            <div className="modal-header">
              <h4>Email Sent</h4>
              <p className="forgot-password">
                If an account registered with that email exists, you&apos;ll receive an email with instructions on
                resetting your password. If you don&apos;t see it, please don&apos;t forget to check your spam folder .
              </p>
            </div>
            <div className="d-grid gap-2" style={{ marginTop: '3em' }}>
              <Button color="#18352a" onClick={() => setModalStep(LoginModalStep.SIGN_IN)}>
                Back to Login
              </Button>
            </div>
          </div>
        )}
        {modalStep === LoginModalStep.RESET_PASSWORD && (
          <div className="rell-modal-body">
            <ArrowLeft
              style={{ width: '24px', height: '24px' }}
              className="back-button"
              onClick={() => {
                setModalStep(LoginModalStep.SIGN_IN);
                setSubmitResults(undefined);
              }}
            />
            <div className="modal-header">
              <h4>Reset Password</h4>
              <p className="forgot-password">Please enter a new password</p>
            </div>
            <Formik
              validationSchema={resetPasswordSchema}
              onSubmit={() => {}}
              initialValues={{ password: '', passwordCheck: '' }}
            >
              {(formikProps) => {
                const { values, touched, errors, handleBlur, handleChange, isSubmitting } = formikProps;
                return (
                  <Form noValidate style={{ marginTop: '1em' }}>
                    <Form.Group className="mb-3" controlId="form.password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.password && !errors.password}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="form.passwordCheck">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="passwordCheck"
                        value={values.passwordCheck}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.passwordCheck && !errors.passwordCheck && !errors.password}
                        isInvalid={!!errors.passwordCheck}
                      />
                      <Form.Control.Feedback type="invalid">{errors.passwordCheck}</Form.Control.Feedback>
                    </Form.Group>

                    {submitResults?.map((submitResult) => (
                      <div key={uuidv4()} className={`result-message ${submitResult.resultType}`}>
                        {submitResult.message}
                      </div>
                    ))}

                    <div className="d-grid gap-2" style={{ marginTop: '3em' }}>
                      <Button
                        color="#18352a"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        onClick={() => handleResetPassword(formikProps)}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
        {modalStep === LoginModalStep.SIGN_UP && (
          <div className="rell-modal-body">
            <ArrowLeft
              style={{ width: '24px', height: '24px' }}
              className="back-button"
              onClick={() => {
                setModalStep(LoginModalStep.START);
                setSubmitResults(undefined);
              }}
            />
            <div className="modal-header">
              <h4>Get started</h4>
              <p>Create your Rell account</p>
            </div>
            <Formik validationSchema={signupSchema} onSubmit={() => {}} initialValues={signupInitialValues}>
              {(formikProps) => {
                const { values, touched, errors, handleBlur, handleChange, isSubmitting } = formikProps;
                return (
                  <Form noValidate style={{ marginTop: '1em' }}>
                    <Row className="mb-3">
                      <Col>
                        <Form.Group as={Col} controlId="form.firstName">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isValid={touched.firstName && !errors.firstName}
                            isInvalid={!!errors.firstName}
                          />
                          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group as={Col} controlId="form.lastName">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isValid={touched.lastName && !errors.lastName}
                            isInvalid={!!errors.lastName}
                          />
                          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="form.email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.email && !errors.email}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="form.password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.password && !errors.password}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="form.passwordCheck">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="passwordCheck"
                        value={values.passwordCheck}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.passwordCheck && !errors.passwordCheck && !errors.password}
                        isInvalid={!!errors.passwordCheck}
                      />
                      <Form.Control.Feedback type="invalid">{errors.passwordCheck}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="form.invitationCode">
                      <Form.Label>Invitation Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="invitationCode"
                        value={values.invitationCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.invitationCode && !errors.invitationCode}
                        isInvalid={!!errors.invitationCode}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">{errors.invitationCode}</Form.Control.Feedback>
                    </Form.Group>

                    {submitResults?.map((submitResult) => (
                      <div key={uuidv4()} className={`result-message ${submitResult.resultType}`}>
                        {submitResult.message}
                      </div>
                    ))}

                    <div className="mb-3 text-center text-muted">
                      By clicking <strong>Sign up</strong>, you agree to Rell&apos;s&nbsp;
                      <UnstyledButton
                        styles={{
                          root: {
                            fontSize: '13px',
                            textDecoration: 'underline',
                            color: 'rgba(var(--bs-link-color-rgb))',
                          },
                        }}
                        onClick={() => {
                          setModalStep(LoginModalStep.T_AND_C);
                        }}
                      >
                        Terms
                      </UnstyledButton>
                      &nbsp;and&nbsp;
                      <UnstyledButton
                        styles={{
                          root: {
                            fontSize: '13px',
                            textDecoration: 'underline',
                            color: 'rgba(var(--bs-link-color-rgb))',
                          },
                        }}
                        onClick={() => {
                          setModalStep(LoginModalStep.PRIVACY);
                        }}
                      >
                        Privacy Policy
                      </UnstyledButton>
                      .
                    </div>
                    <div className="d-grid">
                      <Button
                        color="#18352a"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        onClick={() => handleSignUp(formikProps)}
                      >
                        Sign up
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
        {modalStep === LoginModalStep.T_AND_C && (
          <div className="rell-modal-body">
            <ArrowLeft
              style={{ width: '24px', height: '24px' }}
              className="back-button"
              onClick={() => {
                setModalStep(LoginModalStep.SIGN_UP);
              }}
            />
            <div className="modal-header">
              <h4>Terms and Conditions</h4>
              <p>Last updated: 02/01/2024</p>
            </div>
            <div className="show_scrollbar">
              <p>
                <strong>1. Introduction</strong>
              </p>
              <p>
                Welcome to Rell (&quot;Company&quot;, &quot;we&quot;, &quot;our&quot;, &quot;us&quot;)! As you have just
                clicked our Terms of Service, please pause, grab a cup of coffee and carefully read the following pages.
                It will take you approximately 20 minutes.
              </p>
              <p>
                These Terms of Service (&quot;Terms&quot;, &quot;Terms of Service&quot;) govern your use of our web
                pages located at {APP_URL_LINK} operated by Rell.
              </p>
              <p>
                Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and
                disclose information that results from your use of our web pages. Please read it&nbsp;
                <UnstyledButton
                  onClick={() => {
                    setModalStep(LoginModalStep.PRIVACY);
                  }}
                >
                  here
                </UnstyledButton>
                .
              </p>
              <p>
                Your agreement with us includes these Terms and our Privacy Policy (&quot;Agreements&quot;). You
                acknowledge that you have read and understood Agreements, and agree to be bound of them.
              </p>
              <p>
                If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but
                please let us know by emailing at shinerjo@gmail.com so we can try to find a solution. These Terms apply
                to all visitors, users and others who wish to access or use Service.
              </p>
              <p> Thank you for being responsible. </p>
              <p>
                <strong>2. Communications</strong>
              </p>
              <p>
                By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional
                materials and other information we may send. However, you may opt out of receiving any, or all, of these
                communications from us by following the unsubscribe link or by emailing at.
              </p>
              <p>
                <strong>3. Refunds</strong>
              </p>
              <p> Except when required by law, paid Subscription fees are non-refundable. </p>
              <p>
                <strong>4. Content</strong>
              </p>
              <p>
                Content found on or through this Service are the property of Rell or used with permission. You may not
                distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in
                part, for commercial purposes or for personal gain, without express advance written permission from us.
              </p>
              <p>
                <strong>5. Prohibited Uses</strong>
              </p>
              <p>
                You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:
              </p>
              <ol type="a">
                <li>In any way that violates any applicable national or international law or regulation.</li>

                <li>
                  For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing
                  them to inappropriate content or otherwise.
                </li>
                <li>
                  To transmit, or procure the sending of, any advertising or promotional material, including any
                  &quot;junk mail&quot;, &quot;chain letter,&quot; &quot;spam,&quot; or any other similar solicitation.
                </li>
                <li>
                  To impersonate or attempt to impersonate Company, a Company employee, another user, or any other
                  person or entity.
                </li>
                <li>
                  In any way that infringes upon the rights of others, or in any way is illegal, threatening,
                  fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or
                  activity.
                </li>
                <li>
                  To engage in any other conduct that restricts or inhibits anyone&apos;s use or enjoyment of Service,
                  or which, as determined by us, may harm or offend Company or users of Service or expose them to
                  liability.
                </li>
              </ol>
              <p> Additionally, you agree not to: </p>
              <ol type="a">
                <li>
                  Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with
                  any other party&apos;s use of Service, including their ability to engage in real time activities
                  through Service.
                </li>
                <li>
                  Use any robot, spider, or other automatic device, process, or means to access Service for any purpose,
                  including monitoring or copying any of the material on Service.
                </li>
                <li>
                  Use any manual process to monitor or copy any of the material on Service or for any other unauthorized
                  purpose without our prior written consent.
                </li>
                <li>Use any device, software, or routine that interferes with the proper working of Service.</li>
                <li>
                  Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or
                  technologically harmful.
                </li>
                <li>
                  Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the
                  server on which Service is stored, or any server, computer, or database connected to Service.
                </li>
                <li>Attack Service via a denial-of-service attack or a distributed denial-of-service attack.</li>
                <li>Take any action that may damage or falsify Company rating.</li>
                <li>Otherwise attempt to interfere with the proper working of Service.</li>
              </ol>

              <p>
                <strong>6. Analytics</strong>
              </p>
              <p> We may use third-party Service Providers to monitor and analyze the use of our Service. </p>
              <p> Google Analytics </p>
              <p>
                Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.
                Google uses the data collected to track and monitor the use of our Service. This data is shared with
                other Google services. Google may use the collected data to contextualise and personalise the ads of its
                own advertising network.
              </p>
              <p>
                For more information on the privacy practices of Google, please visit the Google Privacy Terms web page:
                <a href="https://policies.google.com/privacy?hl=en"> https://policies.google.com/privacy?hl=en </a>
              </p>
              <p>
                We also encourage you to review the Google&apos;s policy for safeguarding your data:&nbsp;
                <a href="https://support.google.com/analytics/answer/6004245">
                  https://support.google.com/analytics/answer/6004245
                </a>
                .
              </p>
              <p>
                <strong>7. No Use By Minors</strong>
              </p>
              <p>
                Service is intended only for access and use by individuals at least eighteen (18) years old. By
                accessing or using any of Company, you warrant and represent that you are at least eighteen (18) years
                of age and with the full authority, right, and capacity to enter into this agreement and abide by all of
                the terms and conditions of Terms. If you are not at least eighteen (18) years old, you are prohibited
                from both the access and usage of Service.
              </p>
              <p>
                <strong>8. Accounts</strong>
              </p>
              <p>
                When you create an account with us, you guarantee that you are above the age of 18, and that the
                information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or
                obsolete information may result in the immediate termination of your account on Service.
              </p>
              <p>
                You are responsible for maintaining the confidentiality of your account and password, including but not
                limited to the restriction of access to your computer and/or account. You agree to accept responsibility
                for any and all activities or actions that occur under your account and/or password, whether your
                password is with our Service or a third-party service. You must notify us immediately upon becoming
                aware of any breach of security or unauthorized use of your account.
              </p>
              <p>
                You may not use as a username the name of another person or entity or that is not lawfully available for
                use, a name or trademark that is subject to any rights of another person or entity other than you,
                without appropriate authorization. You may not use as a username any name that is offensive, vulgar or
                obscene.
              </p>
              <p>
                We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in
                our sole discretion.
              </p>
              <p>
                <strong>9. Intellectual Property</strong>
              </p>
              <p>
                Service and its original content (excluding Content provided by users), features and functionality are
                and will remain the exclusive property of Rell and its licensors. Service is protected by copyright,
                trademark, and other laws of the United States. Our trademarks and trade dress may not be used in
                connection with any product or service without the prior written consent of Rell.
              </p>
              <p>
                <strong>10. Copyright Policy</strong>
              </p>
              <p>
                We respect the intellectual property rights of others. It is our policy to respond to any claim that
                Content posted on Service infringes on the copyright or other intellectual property rights
                (&quot;Infringement&quot;) of any person or entity.
              </p>
              <p>
                If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work
                has been copied in a way that constitutes copyright infringement, please submit your claim via email to
                shinerjo@gmail.com, with the subject line: &quot;Copyright Infringement&quot; and include in your claim
                a detailed description of the alleged Infringement as detailed below, under &quot;DMCA Notice and
                Procedure for Copyright Infringement Claims&quot;
              </p>
              <p>
                You may be held accountable for damages (including costs and attorneys&apos; fees) for misrepresentation
                or bad-faith claims on the infringement of any Content found on and/or through Service on your
                copyright.
              </p>
              <p>
                <strong>11. DMCA Notice and Procedure for Copyright Infringement Claims</strong>
              </p>
              <p>
                You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our
                Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
              </p>
              <ol type="a">
                <li>
                  an electronic or physical signature of the person authorized to act on behalf of the owner of the
                  copyright&apos;s interest;
                </li>
                <li>
                  a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web
                  page address) of the location where the copyrighted work exists or a copy of the copyrighted work;
                </li>
                <li>
                  identification of the URL or other specific location on Service where the material that you claim is
                  infringing is located;
                </li>
                <li>your address, telephone number, and email address;</li>
                <li>
                  a statement by you that you have a good faith belief that the disputed use is not authorized by the
                  copyright owner, its agent, or the law;
                </li>
                <li>
                  a statement by you, made under penalty of perjury, that the above information in your notice is
                  accurate and that you are the copyright owner or authorized to act on the copyright owner&apos;s
                  behalf.
                </li>
              </ol>
              <p> You can contact our Copyright Agent via email at shinerjo@gmail.com </p>
              <p>
                <strong>12. Error Reporting and Feedback</strong>
              </p>
              <p>
                You may provide us either directly at shinerjo@gmail.com or via third party sites and tools with
                information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints,
                and other matters related to our Service (&quot;Feedback&quot;). You acknowledge and agree that: (i) you
                shall not retain, acquire or assert any intellectual property right or other right, title or interest in
                or to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii) Feedback does
                not contain confidential information or proprietary information from you or any third party; and (iv)
                Company is not under any obligation of confidentiality with respect to the Feedback. In the event the
                transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant
                Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable,
                unlimited and perpetual right to use (including copy, modify, create derivative works, publish,
                distribute and commercialize) Feedback in any manner and for any purpose.
              </p>
              <p> The third party sites and tools mentioned above include the following: </p>
              <p> Sentry </p>
              <p>
                Sentry is open-source error tracking solution provided by Functional Software Inc. More information is
                available here: <a href="https://sentry.io/privacy/"> https://sentry.io/privacy/ </a>
              </p>
              <p>
                <strong>13. Links To Other Web Sites</strong>
              </p>
              <p>
                Our Service may contain links to third party web sites or services that are not owned or controlled by
                Rell.
              </p>
              <p>
                Rell has no control over, and assumes no responsibility for the content, privacy policies, or practices
                of any third party web sites or services. We do not warrant the offerings of any of these
                entities/individuals or their websites.
              </p>
              <p>
                YOU ACKNOWLEDGE AND AGREE THAT RELL SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY
                DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH
                CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.
              </p>
              <p>
                WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR
                SERVICES THAT YOU VISIT.
              </p>
              <p>
                <strong>14. Disclaimer Of Warranty</strong>
              </p>
              <p>
                THESE SERVICES ARE PROVIDED BY COMPANY ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS.
                COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF
                THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR
                USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
              </p>
              <p>
                NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT
                TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT
                LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT
                THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE,
                RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER
                THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY
                SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
              </p>
              <p>
                COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
                OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS
                FOR PARTICULAR PURPOSE.
              </p>
              <p>
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </p>
              <p>
                <strong>15. Limitation Of Liability</strong>
              </p>
              <p>
                EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS
                HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES
                (INCLUDING ATTORNEYS&apos; FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT
                TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN
                ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS
                AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM
                THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR
                REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS
                PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT
                PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR
                PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR
                CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
              </p>
              <p>
                <strong>16. Termination</strong>
              </p>
              <p>
                We may terminate or suspend your account and bar access to Service immediately, without prior notice or
                liability, under our sole discretion, for any reason whatsoever and without limitation, including but
                not limited to a breach of Terms.
              </p>
              <p> If you wish to terminate your account, you may simply discontinue using Service. </p>
              <p>
                All provisions of Terms which by their nature should survive termination shall survive termination,
                including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of
                liability.
              </p>
              <p>
                <strong>17. Governing Law</strong>
              </p>
              <p>
                These Terms shall be governed and construed in accordance with the laws of State of New York without
                regard to its conflict of law provisions.
              </p>
              <p>
                Our failure to enforce any right or provision of these Terms will not be considered a waiver of those
                rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining
                provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us
                regarding our Service and supersede and replace any prior agreements we might have had between us
                regarding Service.
              </p>
              <p>
                <strong>18. Changes To Service</strong>
              </p>
              <p>
                We reserve the right to withdraw or amend our Service, and any service or material we provide via
                Service, in our sole discretion without notice. We will not be liable if for any reason all or any part
                of Service is unavailable at any time or for any period. From time to time, we may restrict access to
                some parts of Service, or the entire Service, to users, including registered users.
              </p>
              <p>
                <strong>19. Amendments To Terms</strong>
              </p>
              <p>
                We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to
                review these Terms periodically.
              </p>
              <p>
                Your continued use of the Platform following the posting of revised Terms means that you accept and
                agree to the changes. You are expected to check this page frequently so you are aware of any changes, as
                they are binding on you.
              </p>
              <p>
                By continuing to access or use our Service after any revisions become effective, you agree to be bound
                by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Service.
              </p>
              <p>
                <strong>20. Waiver And Severability</strong>
              </p>
              <p>
                No waiver by Company of any term or condition set forth in Terms shall be deemed a further or continuing
                waiver of such term or condition or a waiver of any other term or condition, and any failure of Company
                to assert a right or provision under Terms shall not constitute a waiver of such right or provision.
              </p>
              <p>
                If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid,
                illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum
                extent such that the remaining provisions of Terms will continue in full force and effect.
              </p>
              <p>
                <strong>21. Acknowledgement</strong>
              </p>
              <p>
                BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF
                SERVICE AND AGREE TO BE BOUND BY THEM.
              </p>
              <p>
                <strong>22. Contact Us</strong>
              </p>
              <p> Please send your feedback, comments, requests for technical support: </p>
              <p>
                By email: <a href="mailto:shinerjo@gmail.com">shinerjo@gmail.com</a>.
              </p>
            </div>
          </div>
        )}
        {modalStep === LoginModalStep.PRIVACY && (
          <div className="rell-modal-body">
            <ArrowLeft
              style={{ width: '24px', height: '24px' }}
              className="back-button"
              onClick={() => {
                setModalStep(LoginModalStep.SIGN_UP);
              }}
            />
            <div className="modal-header">
              <h4>Privacy Policy</h4>
              <p>Effective date: 02/01/2024</p>
            </div>
            <div className="show_scrollbar">
              <p>
                <strong>Introduction</strong>
              </p>
              <p>Welcome to Rell Maps</p>
              <p>
                Rell Maps (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates &nbsp;
                {APP_URL_LINK} (hereinafter referred to as &quot;Service&quot;).
              </p>
              <p>
                Our Privacy Policy governs your visit to {APP_URL_LINK}, and explains how we collect, safeguard and
                disclose information that results from your use of our Service.
              </p>
              <p>
                We use your data to provide and improve Service. By using Service, you agree to the collection and use
                of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the
                terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
              </p>
              <p>
                Our Terms and Conditions (&quot;Terms&quot;) govern all use of our Service and together with the Privacy
                Policy constitutes your agreement with us (&quot;agreement&quot;).
              </p>
              <p>
                <strong>Definitions</strong>
              </p>
              <p>SERVICE means the {APP_URL_LINK} website operated by Rell Maps.</p>
              <p>
                PERSONAL DATA means data about a living individual who can be identified from those data (or from those
                and other information either in our possession or likely to come into our possession).
              </p>
              <p>
                USAGE DATA is data collected automatically either generated by the use of Service or from Service
                infrastructure itself (for example, the duration of a page visit).
              </p>
              <p>COOKIES are small files stored on your device (computer or mobile device).</p>
              <p>
                DATA CONTROLLER means a natural or legal person who (either alone or jointly or in common with other
                persons) determines the purposes for which and the manner in which any personal data are, or are to be,
                processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.
              </p>
              <p>
                DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal person who processes the data on
                behalf of the Data Controller. We may use the services of various Service Providers in order to process
                your data more effectively.
              </p>
              <p>DATA SUBJECT is any living individual who is the subject of Personal Data.</p>
              <p>
                THE USER is the individual using our Service. The User corresponds to the Data Subject, who is the
                subject of Personal Data.
              </p>
              <p>
                <strong>Information Collection and Use</strong>
              </p>
              <p>
                We collect several different types of information for various purposes to provide and improve our
                Service to you.
              </p>
              <p>
                <strong>Types of Data Collected</strong>
              </p>
              <p>Personal Data</p>
              <p>
                While using our Service, we may ask you to provide us with certain personally identifiable information
                that can be used to contact or identify you (&quot;Personal Data&quot;). Personally identifiable
                information may include, but is not limited to:
              </p>
              <ul>
                <li>Email address</li>

                <li>First name and last name</li>

                <li>Cookies and Usage Data</li>
              </ul>
              <p>
                We may use your Personal Data to contact you with newsletters, marketing or promotional materials and
                other information that may be of interest to you. You may opt out of receiving any, or all, of these
                communications from us by following the unsubscribe link.
              </p>
              <p>Usage Data</p>
              <p>
                We may also collect information that your browser sends whenever you visit our Service or when you
                access Service by or through a mobile device (&quot;Usage Data&quot;).
              </p>
              <p>
                This Usage Data may include information such as your computer&apos;s Internet Protocol address (e.g. IP
                address), browser type, browser version, the pages of our Service that you visit, the time and date of
                your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
              </p>
              <p>
                When you access Service with a mobile device, this Usage Data may include information such as the type
                of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your
                mobile operating system, the type of mobile Internet browser you use, unique device identifiers and
                other diagnostic data.
              </p>
              <p>Location Data</p>
              <p>
                We may use and store information about your location if you give us permission to do so (&quot;Location
                Data&quot;). We use this data to provide features of our Service, to improve and customize our Service.
              </p>
              <p>
                You can enable or disable location services when you use our Service at any time by way of your device
                settings.
              </p>
              <p>Tracking Cookies Data</p>
              <p>
                We use cookies and similar tracking technologies to track the activity on our Service and we hold
                certain information.
              </p>
              <p>
                Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies
                are sent to your browser from a website and stored on your device. Other tracking technologies are also
                used such as beacons, tags and scripts to collect and track information and to improve and analyze our
                Service.
              </p>
              <p>
                You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However,
                if you do not accept cookies, you may not be able to use some portions of our Service.
              </p>
              <p>Examples of Cookies we use:</p>
              <ul>
                <li>Session Cookies: We use Session Cookies to operate our Service.</li>

                <li>
                  Preference Cookies: We use Preference Cookies to remember your preferences and various settings.
                </li>

                <li>Security Cookies: We use Security Cookies for security purposes.</li>

                <li>
                  Advertising Cookies: Advertising Cookies are used to serve you with advertisements that may be
                  relevant to you and your interests.
                </li>
              </ul>
              <p>
                <strong>Use of Data</strong>
              </p>
              <p>Rell Maps uses the collected data for various purposes:</p>
              <ul>
                <li>to provide and maintain our Service;</li>

                <li>to notify you about changes to our Service;</li>

                <li>to allow you to participate in interactive features of our Service when you choose to do so;</li>

                <li>to provide customer support;</li>

                <li>to gather analysis or valuable information so that we can improve our Service;</li>

                <li>to monitor the usage of our Service;</li>

                <li>to detect, prevent and address technical issues;</li>

                <li>to fulfill any other purpose for which you provide it;</li>

                <li>
                  to carry out our obligations and enforce our rights arising from any contracts entered into between
                  you and us, including for billing and collection;
                </li>

                <li>
                  to provide you with notices about your account and/or subscription, including expiration and renewal
                  notices, email-instructions, etc.;
                </li>

                <li>
                  to provide you with news, special offers and general information about other goods, services and
                  events which we offer that are similar to those that you have already purchased or enquired about
                  unless you have opted not to receive such information;
                </li>

                <li>in any other way we may describe when you provide the information;</li>

                <li>for any other purpose with your consent.</li>
              </ul>
              <p>
                <strong>Retention of Data</strong>
              </p>
              <p>
                We will retain your Personal Data only for as long as is necessary for the purposes set out in this
                Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our
                legal obligations (for example, if we are required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
              </p>
              <p>
                We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a
                shorter period, except when this data is used to strengthen the security or to improve the functionality
                of our Service, or we are legally obligated to retain this data for longer time periods.
              </p>
              <p>
                <strong>Transfer of Data</strong>
              </p>
              <p>
                Your information, including Personal Data, may be transferred to - and maintained on - computers located
                outside of your state, province, country or other governmental jurisdiction where the data protection
                laws may differ from those of your jurisdiction.
              </p>
              <p>
                If you are located outside United States and choose to provide information to us, please note that we
                transfer the data, including Personal Data, to United States and process it there.
              </p>
              <p>
                Your consent to this Privacy Policy followed by your submission of such information represents your
                agreement to that transfer.
              </p>
              <p>
                Rell Maps will take all the steps reasonably necessary to ensure that your data is treated securely and
                in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an
                organisation or a country unless there are adequate controls in place including the security of your
                data and other personal information.
              </p>
              <p>
                <strong>Disclosure of Data</strong>
              </p>
              <p>We may disclose personal information that we collect, or you provide:</p>
              <ul>
                <li>Disclosure for Law Enforcement.</li>
              </ul>
              <p>
                Under certain circumstances, we may be required to disclose your Personal Data if required to do so by
                law or in response to valid requests by public authorities.
              </p>
              <ul>
                <li>Business Transaction.</li>
              </ul>
              <p>
                If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be
                transferred.
              </p>
              <ul>
                <li>Other cases.</li>
              </ul>

              <p>We may disclose your information also:</p>
              <ul>
                <li>to our subsidiaries and affiliates;</li>

                <li>
                  if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the
                  Company, our customers, or others.
                </li>
              </ul>
              <p>
                <strong>Security of Data</strong>
              </p>
              <p>
                The security of your data is important to us but remember that no method of transmission over the
                Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable
                means to protect your Personal Data, we cannot guarantee its absolute security.
              </p>
              <p>
                <strong>Service Providers</strong>
              </p>
              <p>
                We may employ third party companies and individuals to facilitate our Service (&quot;Service
                Providers&quot;), provide Service on our behalf, perform Service-related services or assist us in
                analysing how our Service is used.
              </p>
              <p>
                These third parties have access to your Personal Data only to perform these tasks on our behalf and are
                obligated not to disclose or use it for any other purpose.
              </p>
              <p>
                <strong>Analytics</strong>
              </p>
              <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
              <p>Google Analytics</p>
              <p>
                Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.
                Google uses the data collected to track and monitor the use of our Service. This data is shared with
                other Google services. Google may use the collected data to contextualise and personalise the ads of its
                own advertising network.
              </p>
              <p>
                For more information on the privacy practices of Google, please visit the Google Privacy Terms web page:
                <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>
              </p>
              <p>
                We also encourage you to review the Google&apos;s policy for safeguarding your data:
                <a href="https://support.google.com/analytics/answer/6004245">
                  https://support.google.com/analytics/answer/6004245
                </a>
                .
              </p>
              <p>
                <strong>CI/CD tools</strong>
              </p>
              <p>We may use third-party Service Providers to automate the development process of our Service.</p>
              <p>GitHub</p>
              <p>GitHub is provided by GitHub, Inc.</p>
              <p>GitHub is a development platform to host and review code, manage projects, and build software.</p>
              <p>
                For more information on what data GitHub collects for what purpose and how the protection of the data is
                ensured, please visit GitHub Privacy Policy page:
                <a href="https://help.github.com/en/articles/github-privacy-statement">
                  https://help.github.com/en/articles/github-privacy-statement
                </a>
                .
              </p>
              <p>
                <strong>Links to Other Sites</strong>
              </p>
              <p>
                Our Service may contain links to other sites that are not operated by us. If you click a third party
                link, you will be directed to that third party&apos;s site. We strongly advise you to review the Privacy
                Policy of every site you visit.
              </p>
              <p>
                We have no control over and assume no responsibility for the content, privacy policies or practices of
                any third party sites or services.
              </p>
              <p>
                <strong>Children&apos;s Privacy</strong>
              </p>
              <p>Our Services are not intended for use by children under the age of 13 (&quot;Children&quot;).</p>
              <p>
                We do not knowingly collect personally identifiable information from Children under 13. If you become
                aware that a Child has provided us with Personal Data, please contact us. If we become aware that we
                have collected Personal Data from Children without verification of parental consent, we take steps to
                remove that information from our servers.
              </p>
              <p>
                <strong>Changes to This Privacy Policy</strong>
              </p>
              <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
                Privacy Policy on this page.
              </p>
              <p>
                We will let you know via email and/or a prominent notice on our Service, prior to the change becoming
                effective and update &quot;effective date&quot; at the top of this Privacy Policy.
              </p>
              <p>
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                Policy are effective when they are posted on this page.
              </p>
              <p>
                <strong>Contact Us</strong>
              </p>
              <p>If you have any questions about this Privacy Policy, please contact us:</p>
              <p>
                By email: <a href="mailto:shinerjo@gmail.com">shinerjo@gmail.com</a>.
              </p>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default LoginModal;
